import styled, { css } from 'styled-components';

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.forms.label.fontSize};
    padding: ${theme.forms.label.padding};
  `}
`;

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2rem;
  color: #809fb8;
  display: flex;
  align-items: center;
`;

export const FieldWrapper = styled.div`
  position: relative;
`;

export const TextField = styled.input`
  ${({ theme, error }) => css`
    background: #dfedf4 0% 0% no-repeat padding-box;
    border: 0.1rem solid ${error ? theme.forms.error : 'transparent'};
    border-radius: 35px;
    padding: 2rem 4.6rem;
    font-size: 16px;
    font-weight: ${theme.forms.fontWeight};
    width: 100%;
    transition: all ${theme.transition} linear;
    color: #2b90c2;

    &::placeholder {
      color: #2b90c2;
    }

    &:focus,
    &:active,
    &:not(&:placeholder-shown) {
      background-color: ${theme.forms.active};
      transition: all ${theme.transition} linear;
    }
  `}
`;

export const ShowPassword = styled.div`
  position: absolute;
  right: 2.6rem;
  top: 55%;
  transform: translateY(-50%);
  font-size: 21px;
  cursor: pointer;
  color: #2b90c2;
`;

export const Error = styled.p`
  ${({ theme }) => css`
    font-size: 1rem;
    color: ${theme.forms.error};
  `}
`;

export const Container = styled.div`
  ${({ theme, styling, icon }) => css`
    display: ${theme.forms.wrapper.display};
    flex-direction: ${theme.forms.wrapper.flexDirection};
    gap: 0.1em;
    position: relative;
    width: 100%;

    ${styling !== 'default' &&
    css`
      ${Label} {
        position: absolute;
        font-size: 2rem;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        left: 3.5rem;
        color: #809fb8;
        transition: ${theme.transition};

        left: ${icon ? '3.5rem' : '1rem'};
      }

      ${TextField} {
        box-shadow: 0px 9px 20px #88a2af48;
        background: #ffffff 0% 0% no-repeat padding-box;
        padding-left: ${icon ? '4.8rem' : '3.5rem'};

        &::placeholder {
          color: transparent;
        }

        &:not(&:placeholder-shown),
        &:active,
        &:focus {
          padding: 2.5rem 3.5rem 1.5rem 4.8rem;
          padding-left: ${icon ? '4.8rem' : '3.5rem'};

          & + ${Label} {
            top: 2rem;
            left: ${icon ? '3.4rem' : '1.9rem'};
            font-size: 1.4rem;
            color: #809fb8;
            transition: ${theme.transition};
          }
        }
      }
    `}
  `}
`;
