import axios from "axios";
import {
  API_URL_MIDDLEWARE,
  PROVIDER_NAME,
  TOKEN_NAME,
} from "../env";
import { getFromLocalStorage } from "../utils/localStorage";

export const httpService = axios.create({
  baseURL: API_URL_MIDDLEWARE,
})

// create interceptor to add token to requests
httpService.interceptors.request.use(config => {
  const token = getFromLocalStorage(TOKEN_NAME);
  const provider = localStorage.getItem(PROVIDER_NAME)
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
    config.headers['provider'] = provider;
  }
  return config
})

// // create interceptor for 401 responses
httpService.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    localStorage.removeItem(TOKEN_NAME)
    if(window.location.pathname !== '/')
      window.location.href = '/'
  }
  return Promise.reject(error)
})