import styled, { css, keyframes } from 'styled-components';

export const ProgressBar = styled.div`
  height: 2rem;
  background-color: rgba(5, 114, 206, 0.2);

  width: 100%;
  overflow: hidden;
  border-radius: 1rem;
`;

export const indeterminateAnimation = keyframes`
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
`;

export const ProgressBarValue = styled.div`
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(0deg, #2c7bb7 0%, #2bb9d9 100%) 0% 0%
    no-repeat padding-box;

  animation: ${indeterminateAnimation} 1s infinite linear;
  border-radius: 1rem;

  transform-origin: 0% 50%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
`;

export const Container = styled.div`
  padding-bottom: 6rem;

  .react-datepicker__time-list-item--disabled {
    display: none;
  }
`;

export const ButtonText = styled.span`
  ${({ theme }) => css`
  color: white;
  text-align: center;
  width: 100%;
  fontFamily: ${theme.font.regular};
  `}
`;


export const Body = styled.div`
  flex-direction: column;
  padding: 3.5rem 2.5rem 15rem 2.5rem;

  background: transparent linear-gradient(0deg, #e5f0f7 0%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 0px 0px 0px 236px;
`;

export const Footer = styled.div`
  padding: 3.5rem 2.5rem 0 2.5rem;
`

export const ActionButtons = styled.div`
  padding: 3.5rem 2.5rem 0 2.5rem;
  border-radius: 0px 0px 0px 236px;
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-bottom: 2.5rem;
  padding: 0 2rem;
`;

export const TopLeft = styled.div`
  display: flex;
  flex-direction: column;

  img {
    margin-bottom: 2.5rem;
  }
`;

export const YSpace = styled.div`
  margin-bottom: 2.5rem;
`;

export const Description = styled.div`
  color: #88a2af;
  text-align: left;
  font-size: 1.6rem;
`;

export const TopRight = styled.div``;

export const ClockIcon = styled.img.attrs({
  src: '/images/icon/clock.svg',
})``;

export const Disclaimer = styled(Description)`
  margin-top: 2.5rem;
  padding: 0 2rem;
  font-size: 2.1em;
`;

export const HeadingDescription = styled(Description)`
  margin-top: 2.5rem;
  padding: 1rem 1rem;
  text-align: center;
  font-size: 2.1em;
  color: #88a2af;
  text-align: left;
  font-size: 2rem;
`;

export const CarouselWrapper = styled.div`
  overflow-x: scroll;
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 3rem 2rem;

  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
`;

export const Carousel = styled.div`
  display: flex;
  gap: 1.8rem;
  width: max-content;
`;

export const CarouselItem = styled.div`
  width: 32.1rem;
  height: 17rem;
  box-shadow: 0px 12px 18px #44675939;
  border-radius: 27px;

  background-color: #ffffff;

  position: relative;
  background-image: url('/images/backgrounds/canabis.jpg');
  background-size: cover;
`;

export const CarouselItemTitle = styled.div`
  background: transparent linear-gradient(180deg, #00452c00 0%, #002913 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  border-radius: 0px 0px 27px 27px;

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  height: 6rem;

  display: flex;
  align-items: flex-end;
  padding-bottom: 1.7rem;
  padding-left: 2.8rem;
  font-size: 1.3rem;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 2.5rem;
  padding: 0 2rem;

  .react-datepicker__input-container input {
    border: 0.2rem solid #40abcb;
    border-radius: 3.1rem;
    height: 6.1rem;
    width: calc(100% - 4rem);
    margin: 1rem 2rem;
    color: #40abcb;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
  }

  .react-datepicker {
    box-shadow: 0px 6px 18px #7f9eb766;
    border-radius: 31px;
    border: none;

    color: #2c7bb7;
    font-weight: 600;
  }

  .react-datepicker__header {
    background-color: #fff;
    border-bottom: 0.1rem solid #d8e4e8;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-container {
    float: none;
    width: inherit;
    width: 90vw;
  }

  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 0.5rem;
    top: 1rem;
  }

  .react-datepicker__navigation--previous {
    left: 0.5rem;
    top: 1rem;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header,
  .react-datepicker__day-name {
    color: #2c7bb7;
    font-weight: 600;
    font-size: 1.9rem;
  }

  .react-datepicker__day-name {
    font-size: 1.4rem;
    width: 3.5rem;
  }

  .react-datepicker__day {
    color: #2c7bb7;
    font-weight: normal;
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
    font-size: 1.4rem;
    vertical-align: middle;

    &--disabled {
      opacity: 0.3;
    }
  }

  .react-datepicker__day--selected {
    color: #fff;
    font-weight: 600;
    border-radius: 100%;
  }
  .react-datepicker__day-names {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .react-datepicker__time-list-item {
    font-size: 1.4rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;

  button {
    width: 49%;
    justify-content: center;

    &:first-of-type {
      background: #dbe9f0 0% 0% no-repeat padding-box;
      color: #2c7bb7;
    }

    &:last-of-type {
      background: transparent linear-gradient(0deg, #2c7bb7 0%, #2bb9d9 100%) 0%
        0% no-repeat padding-box;
    }
  }
`;

export const DisclaimerPosition = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Select = styled.select`
  margin-top: 20px;
  background-color: white;
  border: none;
  color: #40abcb;
  border: 2px solid #40abcb;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 25px 30px;
  border-radius: 30px;
  outline: none;
  background-image: url('/images/icon/arrow-bottom-blue.svg');
  background-repeat: no-repeat;
  padding-right: 2.5rem;
  background-position: 94% 50%;
  appearance: none;
  width: 100%;
`;

export const WrapSelect = styled.div`
  padding: 20px;
  width: 100%;
`;
