import React, { createContext, useEffect, useState } from "react";
import { useContext } from "react";
import { httpService } from "../../services/axios";
import { useUser } from "../UserProvider";

export const QueueContext = createContext();

export const useQueue = () => {
  const context = useContext(QueueContext);
  return context;
};

export const QueueProvider = ({ children }) => {
  const [isWorking, setIsWorking] = useState(false);
  const [canSchedule, setCanSchedule] = useState(true);
  const [loading, setLoading] = useState(false);

  const { userInfo: { jwt } } = useUser();

  useEffect(() => {
    const handleFetchQueue = async () => {
        try {
            setLoading(true);
            const { data: { data } } = await httpService.get('/medical-appointment/queue/isAvailable', {
              params: {
                  period: new Date().toISOString()
              }
            });
            setCanSchedule(data.canSchedule);
            setIsWorking(data.isWorking);
        } catch (error) {
            console.log('Erro ao buscar se a fila está disponivel');
        } finally {
            setLoading(false);
        }
    };

    if(jwt)
        handleFetchQueue()

  }, [jwt]);

  return (
    <QueueContext.Provider
      value={{
        hasDoctorsAvailable: isWorking,
        setIsWorking,
        canSchedule,
        loading,
        setLoading,
      }}
    >
      {children}
    </QueueContext.Provider>
  );
};
