import styled, { css } from 'styled-components';

export const Container = styled.button`
  ${({ theme, $block, variant, size }) => css`
    border: none;
    width: 100%;
    height: auto;
    background: transparent linear-gradient(180deg, #00abd1 0%, #0260a9 100%) 0%
      0% no-repeat padding-box;
    font-family: ${theme.font.bold};
    border-radius: 120px;
    padding: 2rem 2.5rem;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
    &:active {
      opacity: 0.7;
      transition: ${theme.transition};
    }
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    ${$block &&
    css`
      width: 100%;
    `}

    ${size === 'large' &&
    css`
      font-size: 18px;

      width: 100%;
      min-height: 60px;
    `}

    ${variant === 'grey' &&
    css`
      background: var(
        --Grey,
        linear-gradient(180deg, #acc9d3 0%, #88a2af 100%)
      );
      color: #fff;
      height: 50px;
    `}

    ${variant === 'outline' &&
    css`
      background: none;
      border: 0.2rem solid #2b90c1;
      color: #2b90c1;
    `}

    ${variant === 'whiteBackground' &&
    css`
      background: #ffffff; /* White background */
      color: #808080; /* Grey text */
      border: 1px solid #e0e0e0; /* Slight border for definition */

      &:hover {
        background: #f5f5f5; /* Slightly darker on hover */
      }
    `}

    ${variant === 'fitBlurBagkround' &&
    css`
      width: fit-content;
      background: #fffff30;
      color: #fff;
      font-family: 'PF Handbook Pro';
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    `}






    ${size === 'small' &&
    css`
      font-size: 16px;
      padding: 1rem 2rem;
    `}

    ${size === 'medium' &&
    css`
      font-size: 18px;
    `}
  `}
`;

export const IconWrapper = styled.div`
  color: ${({ $iconColor }) => $iconColor};
  color: white;
  font-size: 25px;
  line-height: 17px;
`;

export const Icon = styled.img.attrs(({ icon, $iconColor, iconSrc }) => ({
  src: iconSrc || `/images/icon/arrow-${icon}-${$iconColor}.svg`,
  alt: `Arrow ${icon}`,
}))``;
