import { useEffect, useState } from "react";
import * as S from "./styles";
import { httpService } from "../../../../services/axios";
import Button from "../../../../components/Button";
import { promiseAwait } from "../../../../utils/promiseAwait";
import Loader from "../Loader";
import { APPOINTMENT } from "../../../../CONSTANTS";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export function Queue({ appointment, setType }) {
  const navigate = useNavigate();
  const [position, setPosition] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [positionLoader, setPositionLoader] = useState(true);
  const [isInQueue, setIsInQueue] = useState(false);

  const handleLeaveQueue = async (appointmentId) => {
    try {
      await httpService.put(`/medical-appointment/${appointmentId}/queue`, {
        isInQueue: false,
        enteredQueueAt: null,
      });
      setIsInQueue(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnterQueue = async (appointmentId) => {
    try {
      await httpService.put(`/medical-appointment/${appointmentId}/queue`, {
        isInQueue: true,
        enteredQueueAt: new Date().toISOString(), // Marca a data e hora atual
      });
      setIsInQueue(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isInQueue && appointment !== null) {
      const handleDoctorEnter = async () => {
        const { data: response } = await httpService.get(
          `/medical-appointment/${appointment}/status`
        );

        if (response?.data?.attributes?.status === "inmeeting") {
          Swal.fire({
            title: "O médico entrou na sala!",
            html: "Estamos te redirecionando ao atendimento!",
            didOpen: () => {
              Swal.showLoading();
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
          });
          clearInterval(intervalDoc)
          await promiseAwait(3000);

          const videoCallRoom = response.data.attributes.room;

          navigate(`/medical-appointment/${videoCallRoom}/call`);
          Swal.close();
        }
      };
      const handleGetQueuePosition = async () => {
        try {
          setPositionLoader(true);
          const { data } = await httpService.get(
            `/medical-appointment/${appointment}/queue`
          );
          setPosition(data.position);
        } catch (error) {
          console.log("error", error);
        } finally {
          setPositionLoader(false);
        }
      };

      handleDoctorEnter();
      handleGetQueuePosition();

      const intervalDoc = setInterval(handleDoctorEnter, 5000);
      const intervalPosition = setInterval(handleGetQueuePosition, 5000);

      return () => {
        clearInterval(intervalPosition);
        clearInterval(intervalDoc);
      };
    }
  }, [appointment, isInQueue, navigate]);

  return (
    <S.Body>
      <S.TopWrapper>
        {isInQueue ? (
          <>
            <S.TopLeft>
              <S.Description>
                Você está na fila de espera, seu médico estará disponível em
                breve
              </S.Description>
              {/* <S.Description>
                  O tempo médio de espera é de: 5 minutos
                </S.Description> */}
              <S.DisclaimerPosition>
                <S.Description>
                  A sua posição é:{" "}
                  {!positionLoader && (
                    <strong>{position <= 0 ? "próximo" : position + 1}</strong>
                  )}
                </S.Description>
                {positionLoader && <Loader />}
              </S.DisclaimerPosition>
            </S.TopLeft>
            <S.TopRight>
              <S.ClockIcon />
            </S.TopRight>
          </>
        ) : (
          <>
            <S.TopLeft>
              <S.Description>
                Você não está na fila de espera. Deseja entrar na fila agora?
              </S.Description>
              <S.YSpace />
              <S.YSpace />
            </S.TopLeft>
          </>
        )}
      </S.TopWrapper>

      {isInQueue ? (
        <>
          <S.ProgressBar>
            <S.ProgressBarValue />
          </S.ProgressBar>
        </>
      ) : (
        <></>
      )}
      <S.Footer>
        <Button
          size="large"
          onClick={() => {
            if (isInQueue) {
              handleLeaveQueue(appointment);
            } else {
              handleEnterQueue(appointment);
            }
          }}
          disabled={isLoading}
        >
          <S.ButtonText>
            {isInQueue ? "Sair da Fila" : "Entrar na Fila"}
          </S.ButtonText>
        </Button>
        {!isInQueue && (
          <S.ButtonTextAppointment
            onClick={() => setType(APPOINTMENT)}
          >
            Fazer agendamento
          </S.ButtonTextAppointment>
        )}
      </S.Footer>
    </S.Body>
  );
}
