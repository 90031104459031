import styled, { css, keyframes } from 'styled-components';

export const Body = styled.div`
  flex-direction: column;
  padding: 3.5rem 2.5rem 15rem 2.5rem;

  background: transparent linear-gradient(0deg, #e5f0f7 0%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 0px 0px 0px 236px;
`;
export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-bottom: 2.5rem;
  padding: 0 2rem;
`;

export const TopLeft = styled.div`
  display: flex;
  flex-direction: column;

  img {
    margin-bottom: 2.5rem;
  }
`;

export const Footer = styled.div`
  padding: 0.5rem 2.5rem 0 2.5rem;
`
export const YSpace = styled.div`
  margin-bottom: 2.5rem;
`;

export const Description = styled.div`
  color: #88a2af;
  text-align: left;
  font-size: 1.6rem;
`;

export const ButtonTextAppointment = styled.h2`
  cursor: pointer;
  margin-top: 2rem;
  ${({ theme }) => css`
  text-align: center;
  width: 100%;
  fontFamily: ${theme.font.regular};`}
`;

export const ButtonText = styled.span`
  ${({ theme }) => css`
  color: white;
  text-align: center;
  width: 100%;
  fontFamily: ${theme.font.regular};
  `}
`;

export const WhatsContent = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const WhatsNumber = styled.span`
  ${({ theme }) => css`
  fontFamily: ${theme.font.regular};`}
  font-size: 2rem;
`;
export const WhatsLogo = styled.img`
  margin-left: 10px;
  margin-bottom: 0px;
`;
