import DatePicker, { registerLocale } from 'react-datepicker';
// import { setHours, setMinutes } from 'date-fns'
import pt from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from 'react-router-dom';

import VideoChat from '../../components/VideoChat';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { httpService } from '../../services/axios';
import { getFromLocalStorage } from '../../utils/localStorage';

registerLocale('pt', pt);

const MedicalAppointment = () => {
  const params = useParams();
  const navigate = useNavigate();


  const [isFinished, setIsFinished] = useState(false);
  const appointment = getFromLocalStorage('@bluemed:medical-appointment-id')

  useEffect(() => {
    Swal.close();

    const handleDoctorEnter = async () => {
      const { data: response } = await httpService.get(
        `/medical-appointment/${appointment}/status`,
      );
      const status = response.data.attributes.status;
      if(status === "done" || status === 'scheduled') {
        clearInterval(interVal);
        response.data.attributes.status === "done" ? setIsFinished(true) : navigate(-1)
      }

      if(status === "canceled") {
        clearInterval(interVal);
        Swal.fire({
          title: 'Consulta encerrada',
          text: 'A consulta foi encerrada, e o médico informou que você não necessita de uma prescrição.',
          confirmButtonText: 'Ok',
        })
        navigate('/profile');
      }
    }

    const interVal = setInterval(handleDoctorEnter, 10000);

    handleDoctorEnter();

    return () => {
      clearInterval(interVal);
    }
  }, [appointment, navigate])

  return (
    <VideoChat
      appointment={appointment}
      roomName={params.room}
      isCustomer
      isFinished={isFinished}
    />
  );

};

export default MedicalAppointment;
