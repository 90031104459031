// styles.ts
import styled from 'styled-components';

export const DocumentCard = styled.div`
  border-radius: 21px;
  border: 1.5px solid var(--BgBlur, rgba(255, 255, 255, 0.3));
  background: var(
    --WhiteBluInverter,
    linear-gradient(180deg, #fff 0%, #f4f9ff 100%)
  );
  box-shadow: 0px 9.344px 35.039px 0px rgba(147, 174, 186, 0.2);
  transition: 0.3s;
  width: 100%;
  margin-bottom: 45px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const DocumentHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px 0px 30px;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const DocumentTitle = styled.h2`
  color: #333;
  margin: 0;
  font-size: 18px;
  display: flex;
  color: #333;
  gap: 0.5em;
`;

export const IconsContainer = styled.div`
  // Adjust the styles as needed for the icons
`;

export const DocumentContent = styled.div`
  display: flex;
  width: fit-content;
  padding: 6px 18px;
  align-items: center;
  gap: 12px;
  color: var(--White, #fff);
  font-family: 'PF Handbook Pro';
  font-size: 17.146px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 17.146px */
  border-radius: 82.299px;
  background: var(--Grey, linear-gradient(180deg, #acc9d3 0%, #88a2af 100%));
`;

// Add specific styles for your icons, buttons, etc.

export const Icon = styled.img`
  height: 22px;
`;

export const DocumentThumbnail = styled.img`
  width: 100%; // or max-width if you want to restrict the size
  border-radius: 5px; // optional, for styled corners
  margin-top: 10px; // space between the title and the image
  // Add any other styles you want for the image
`;

export const ThumbImage = styled.img`
  width: 95%;
  height: 210px;
  border-radius: 10px;
  margin-bottom: 25px;
`;

export const ContentImage = styled.div`
  display: flex;
  justify-content: center;
`
