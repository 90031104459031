import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../../../components/Input";
import * as S from "../../styles";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { FiLock, FiMail, FiUser } from "react-icons/fi";
import Button from "../../../../../components/Button";
import { Step1Schema } from "./schema";
import { useCallback } from "react";

const Step1 = ({ handleNext, loading, value, setValueStep }) => {
  const methods = useForm({
    resolver: yupResolver(Step1Schema),
    defaultValues: value,
  });

  const errors = methods.formState.errors;

  const submit = useCallback((values) => {
    handleNext({ ...value, ...values });
    setValueStep(values);
  }, [value])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <S.Group>
          <Input
            styling="secondary"
            placeholder="Nome completo"
            label="Nome"
            name="name"
            error={errors.name?.message}
            icon={<FiUser size={20} />}
          />

          <Input
            styling="secondary"
            label="Email"
            placeholder="Email"
            name="email"
            error={errors.email?.message}
            icon={<FiMail size={20} />}
          />

          <Input
            styling="secondary"
            label="(+55) 00 0 0000-0000"
            placeholder="(+55) 00 0 0000-0000"
            mask="+55 (99) 99999-9999"
            name="whatsapp"
            error={errors.whatsapp?.message}
            icon={<AiOutlineWhatsApp size={20} />}
          />


          <Input
            styling="secondary"
            label="Senha"
            placeholder="senha"
            type="password"
            name="password"
            error={errors.password?.message}
            icon={<FiLock size={20} />}
          />

          <Input
            styling="secondary"
            label="Confirmar senha"
            placeholder="Confirmar senha"
            type="password"
            name="confirmPassword"
            error={errors.confirmPassword?.message}
            icon={<FiLock size={20} />}
          />


          <Button disabled={loading}>Atualizar cadastro</Button>
        </S.Group>
      </form>
    </FormProvider>
  );
};

export default Step1;
