import * as S from './styles'

const Feedbacks = ({ show, icon, title, description, type = 'primary', loading }) => {
  return (
    <S.Container $show={show} type={type}>
      <S.Icon icon={icon} />
      <S.Title>{typeof title === 'string' ? title : title()}</S.Title>
      <S.Description>{description()}</S.Description>
      {loading && (
        <S.Loading>
          <S.LoadingIcon />
        </S.Loading>
      )}
    </S.Container>
  )
}

export default Feedbacks
