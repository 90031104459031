import { RiUserSettingsLine } from 'react-icons/ri';
import Swal from 'sweetalert2';
import * as S from './styles';

import { useCallback, useEffect, useState } from 'react';
import { httpService } from '../../../services/axios';

import Step1 from './Components/step1';

import Header from '../../../components/Header';

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem('userInfo'));
  const [fieldValue, setFieldValue] = useState({
    step1: {
      name: user.name,
      whatsapp: user.whatsapp,
      email: user.email,
      password: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    document.querySelector('body').classList.remove('-no-overflow');
  }, []);

  const getValues = useCallback(() => {
    const keys = Object.keys(fieldValue);
    let value = {};

    for (let index = 0; index < keys.length; index++) {
      value = {
        ...value,
        ...fieldValue[keys[index]],
      };
    }

    return value;
  }, [fieldValue]);

  const onSubmit = useCallback(
    async (payload) => {
      try {
        const data = { ...getValues(), ...payload };

        setLoading(true);

        await httpService.put(`/users`, {
          ...data,
        });

        Swal.fire({
          title: 'Conta atualizada com sucesso!',
          icon: 'success',
        });
      } catch (error) {
        Swal.fire({
          title: `Erro ao atualizar o cadastro, tente novamente.`,
          icon: 'warning',
        });
      } finally {
        setLoading(false);
      }
    },
    [getValues],
  );

  const handleNext = (values) => {
    onSubmit({ ...values });
  };

  const setValueStep = (step, data) => {
    setFieldValue((value) => ({
      ...value,
      [step]: data,
    }));
  };

  return (
    <>
      <S.Container>
        <Header />

        <S.Content>
          <S.Title>
            <RiUserSettingsLine />
            Minhas Informações
          </S.Title>
          <Step1
            handleNext={handleNext}
            value={fieldValue.step1}
            setValueStep={(value) => setValueStep('step1', value)}
            loading={loading}
          />
        </S.Content>
      </S.Container>
    </>
  );
};

export default Signup;
