import { useCallback, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import Video from 'twilio-video';
import { httpService } from '../../services/axios';
import FeedbackFinishCall from '../FeedbackFinishCall';
import * as S from './styles';

let currentRoom = null;

const VideoChat = ({ roomName, appointment, isCustomer, isFinished }) => {
  const user = JSON.parse(localStorage.getItem('userInfo'));
  
  const localVideoRef = useRef();
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);

  const [isRemoteAudioEnabled, setIsRemoteAudioEnabled] = useState(true);
  const [isRemoteVideoEnabled, setIsRemoteVideoEnabled] = useState(true);

  const [localMediaStream, setLocalMediaStream] = useState(null);
  const [localUserInfo, setLocalUserInfo] = useState(null);
  const [remoteUser, setRemoteUser] = useState(null);
  const [userDisconnected, setUserDisconnected] = useState(false);

  const muteOrUnmuteYourMedia = (room, kind, action) => {
    const publications =
      kind === 'audio'
        ? room?.localParticipant?.audioTracks
        : room?.localParticipant?.videoTracks;

    publications.forEach((publication) => {
      if (action === 'mute') {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });
  };

  const toggleMute = () => {
    const muteOrUnmute = !isAudioEnabled ? 'unmute' : 'mute';
    muteOrUnmuteYourMedia(currentRoom, 'audio', muteOrUnmute);
    setIsAudioEnabled(!isAudioEnabled);
  };

  const toggleVideo = () => {
    localMediaStream.getVideoTracks().forEach((track) => {
      track.enabled = !track.enabled;

      const muteOrUnmute = track.enabled ? 'unmute' : 'mute';
      muteOrUnmuteYourMedia(currentRoom, 'video', muteOrUnmute);
      setIsVideoEnabled(!isVideoEnabled);
    });
  };

  function participantConnected(participant) {
    console.log('connected', participant);
    setUserDisconnected(false);

    const div = document.createElement('div');
    div.id = participant.sid;

    setRemoteUser(participant.identity);

    participant.on('trackSubscribed', (track) => trackSubscribed(div, track));
    participant.on('trackUnsubscribed', trackUnsubscribed);
    participant.on('trackDisabled', (track) => trackDisabled(div, track));
    participant.on('trackEnabled', (track) => trackEnable(div, track));

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        trackSubscribed(div, publication.track);
      }
    });

    document.getElementById('remote-media')?.appendChild(div);
  }

  function participantDisconnected(participant) {
    console.log('Participant "%s" disconnected', participant.identity);
    document.getElementById(participant.sid)?.remove();
    setRemoteUser(null);
    setUserDisconnected(true);
  }

  function trackSubscribed(div, track) {
    div.appendChild(track.attach());
  }

  function trackUnsubscribed(track) {
    track.detach().forEach((element) => element?.remove());
  }

  function trackDisabled(div, track) {
    if (track.kind === 'audio') {
      setIsRemoteAudioEnabled(false);
    } else {
      setIsRemoteVideoEnabled(false);
      div.classList.add('-disabled');
    }
  }

  function trackEnable(div, track) {
    if (track.kind === 'audio') {
      setIsRemoteAudioEnabled(true);
    } else {
      setIsRemoteVideoEnabled(true);
      div.classList?.remove('-disabled');
    }
  }

  // const leaveRoom = useCallback(async () => {
  //   if (isCustomer) {
  //     const { isConfirmed } = await Swal.fire({
  //       icon: 'info',
  //       title: 'Você realmente deseja sair da sala?',
  //       showCancelButton: true,
  //       confirmButtonText: 'Sim',
  //       cancelButtonText: 'Não',
  //     });

  //     if (!isConfirmed) {
  //       return;
  //     }

  //     setFinalized(true);
  //   }

  //   handleLeave();
  // }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const handleAddParticipant = async (roomName, id) => {
      const name = user?.name || 'Usuário';
      try {
        const { data, withError } = await httpService.post(
          '/medical-appointment/generate-token/customer',
          {
            name,
            id: String(id),
            room: roomName,
          },
          { signal }
        )

        if(withError) return;

        Video.connect(data, { name: roomName }).then((room) => {
          currentRoom = room;
          setLocalUserInfo(room.localParticipant);
          room.participants.forEach(participantConnected);
          room.on('participantConnected', participantConnected);
          room.on('participantDisconnected', participantDisconnected);
          room.once('disconnected', (error) => {
            room.participants.forEach(participantDisconnected);
            console.log('once disconnected', error)
          });
        });

      } catch (error) {
        if (error.response?.status === 403) {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: 'Você não tem permissão para acessar esta sala, caso o erro persista, entre em contato com o suporte.',
          });
  
          return { withError: true }
        }
      };
    };

    if (roomName && appointment) {
      handleAddParticipant(roomName, appointment);
    }
    
    return () => {
      controller.abort();
    };

  }, [roomName, appointment, user?.name]);

  useEffect(() => {
    if(localMediaStream === null){
      const startVideo = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          setLocalMediaStream(stream);
          localVideoRef.current.srcObject = stream;
        } catch (error) {
          console.error('Error accessing media devices.', error);
        }
      };
      startVideo();
    }
    return () => {
      if(localMediaStream !== null){
        localMediaStream?.getTracks().forEach((track) => track.stop());
      }
    };
  }, [localMediaStream]);

  useEffect(() => {
    const handleLeave = () => {
      currentRoom.disconnect();
      currentRoom = null;
    };
    return () => {
      if(currentRoom != null){
        console.log('handleLeave', new Date().toISOString());
        handleLeave()
      }
    }
  }, []);

  if (isFinished) {
    return <FeedbackFinishCall />;
  }

  return (
    <S.Container>
      {userDisconnected && (
        <S.DisconnectedUser>Usuário desconectado</S.DisconnectedUser>
      )}
      <S.Remote id='remote-media' />

      <S.Me>
        <S.Top>
          {remoteUser && (
            <S.RemoteUserName>
              {remoteUser}
              <S.MicrophoneAction muted={!isRemoteAudioEnabled} />
              <S.VideoAction muted={!isRemoteVideoEnabled} />
            </S.RemoteUserName>
          )}

          <S.MeInfo>
            <S.Video ref={localVideoRef} autoPlay={true} />
            <S.MeName>{localUserInfo?.identity}</S.MeName>
          </S.MeInfo>
        </S.Top>

        <S.Actions>
          {/* <S.EndCall onClick={leaveRoom} /> */}
          <S.MicrophoneAction muted={!isAudioEnabled} onClick={toggleMute} />
          <S.VideoAction muted={!isVideoEnabled} onClick={toggleVideo} />
        </S.Actions>
      </S.Me>
    </S.Container>
  );
};

export default VideoChat;
