import { useState, useEffect } from "react";
import { LuCheckCircle2 } from "react-icons/lu";
import { RiHomeLine } from "react-icons/ri";

import { useNavigate } from "react-router-dom";
import AttachDocuments from "../AttachDocuments";
import Button from "../Button";
import * as S from "./styles";
import { httpService } from "../../services/axios";
import { API_URL_BLUEDREAM, TOKEN_NAME } from "../../env";

const FeedbackFinishCall = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('userInfo'));
  const name = user?.name || 'Usuário';


  const [list, setList] = useState([]);
  const [count, setCount] = useState(30);

  const handleGetDocuments = async () => {
    const { data } = await httpService.get("/medical-appointment/documents");

    if (!data?.length) return;

    const listInfo = data.map((item) => ({
      title: item.attributes.title,
      id: item.id,
    }));

    setList(listInfo);
  };
  useEffect(() => {
    handleGetDocuments();
  }, []);

  useEffect(() => {
    if (count <= 0) {
      const sharedToken = localStorage.getItem("sharedToken");
      window.location.replace(`${API_URL_BLUEDREAM}/auth/${sharedToken}`);
    }

    const timerId = setTimeout(() => {
      setCount(count - 1);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [count]);

  const lastPrescription = list[list?.length - 1];

  return (
    <S.Feedback>
      <S.Header>
        <S.Logo />
      </S.Header>

      <S.Content>
        <S.SucessBox>
          <LuCheckCircle2 />
          Realizada com sucesso
        </S.SucessBox>
        <S.Title>Parabéns {name} 🎉</S.Title>

        <S.SubTitle>
          Você deu um novo passo no caminho da sua jornada legal em busca do seu
          tratamento
        </S.SubTitle>

        {lastPrescription && (
          <AttachDocuments
            title={lastPrescription?.attributes?.title}
            id={lastPrescription?.id}
          />
        )}

        <S.RedirectMessage>
          Você será redirecionado para a loja em {count} segundos
        </S.RedirectMessage>

        <Button
          variant="fitBlurBagkround"
          size="medium"
          $block
          icon={<RiHomeLine />}
          onClick={() => navigate("/profile")}
        >
          Acessar sua conta
        </Button>
      </S.Content>

      <S.Copyright>
        Todos direitos reservados 2023<br></br>© Blue Mëd
      </S.Copyright>
    </S.Feedback>
  );
};

export default FeedbackFinishCall;
