/* eslint-disable no-unused-expressions */
import { useEffect, useMemo, useState } from 'react';
import { httpService } from '../../../services/axios';
import { isSunday, isMonday, isTuesday, isWednesday, isThursday, isFriday, isSaturday, parse, isWithinInterval } from 'date-fns';

const dayFunctions = {
  'domingo': isSunday,
  'segunda': isMonday,
  'terca': isTuesday,
  'quarta': isWednesday,
  'quinta': isThursday,
  'sexta': isFriday,
  'sabado': isSaturday,
};

const useMedicalAppointment = (selectedDoctorId) => {
  const [doctors, setDoctors] = useState([]);
  const [doctorWeekDays, setDoctorWeekDays] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const weekDaysKeys = useMemo(() => Object.keys(doctorWeekDays), [doctorWeekDays]);

  const checkDate = (date) => {
    return weekDaysKeys.some(day => dayFunctions[day](date));
  };

  const filterWeekDay = (date) => {
    return weekDaysKeys.filter(day => dayFunctions[day](date));
  }

  const checkTime = (date) =>{
    const weekDay = filterWeekDay(date)
      if(weekDay && doctorWeekDays[weekDay]?.working){
          const { startHour, finishHour, intervalHour, returnHour } = doctorWeekDays[weekDay];
          const start = parse(startHour, 'HH:mm', new Date());
          const finish = parse(finishHour, 'HH:mm', new Date());
          const intervalStart = parse(intervalHour, 'HH:mm', new Date());
          const intervalEnd = parse(returnHour, 'HH:mm', new Date());
          const time = parse(date.toTimeString().substring(0, 5), 'HH:mm', new Date());
          // Verifica se está dentro do horário de trabalho
          const withinWorkHours = isWithinInterval(time, { start, end: finish });
          // Verifica se está fora do horário de intervalo
          const outsideBreakHours = !isWithinInterval(time, { start: intervalStart, end: intervalEnd });
          return withinWorkHours && outsideBreakHours
      }
      return false;  
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const getDoctors = async () => {
      setIsLoading(true);
      try {
        const { data: response } = await httpService.get(`/users/doctors` , { signal });
  
        const avaliable = response.filter((doctor) => {
          return doctor?.schedule !== null;
        });
  
        setDoctors(avaliable);
      } catch (error) {
        setDoctors([]);
      } finally {
        setIsLoading(false);
      }
    };

    getDoctors();
    return () => {
      controller.abort();
    };

  }, []);

  useEffect(() => {
    if(selectedDoctorId && doctors.length > 0) {
      const doc = doctors.find(v => v.id === selectedDoctorId);
      setDoctorWeekDays(doc.schedule.attributes.weekdays)
    }
  },[doctors, selectedDoctorId]);

  return {
    doctors,
    isLoading,
    checkDate,
    checkTime,
  };
};

export default useMedicalAppointment;
