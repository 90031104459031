const STEP_1 = 1;
const STEP_2 = 2;
const STEP_3 = 3;
const STEP_4 = 4;
const STEP_5 = 5;

export const STEPS = {
  STEP_1,
  STEP_2,
  STEP_3,
  STEP_4,
  STEP_5,
};
