import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  #remote-media {
    position: relative;

    .-disabled {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: #000;

        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const Me = styled.div`
  position: absolute;
  bottom: 0rem;
  padding: 2rem;

  width: 100%;
`;

export const Video = styled.video`
  width: 11rem;
  height: 16rem;
  border-radius: 15px;
  object-fit: cover;
`;

export const Remote = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;

  > div {
    width: 100%;
    height: 100%;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Actions = styled.div`
  border-radius: 37.634px;
  background: linear-gradient(
    180deg,
    rgba(242, 248, 252, 0.7) 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  backdrop-filter: blur(8.553187370300293px);
  padding: 1rem 1.4rem;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const ActionButton = styled.div`
  cursor: pointer;
  background-repeat: no-repeat;
  border-radius: 100%;
  background-position: center;
  width: 4.8rem;
  height: 4.8rem;
`;

export const EndCall = styled(ActionButton)`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
  opacity: ${(props) => (props.disabled ? '0.3' : '1')};

  background-color: #f33060;
  background-image: url('/images/icon/call-end.svg');
  width: 5.6rem;
  height: 5.6rem;
  filter: drop-shadow(0px 0px 0.948px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 1.896px 5.688px rgba(239, 35, 85, 0.12))
    drop-shadow(0px 15.168px 22.753px rgba(239, 35, 85, 0.2));
  margin-left: 0;
  margin-right: auto;
`;

export const MicrophoneAction = styled(ActionButton)`
  ${({ muted }) => css`
    background-color: #fff;
    background-image: url(${muted
      ? '/images/icon/call-voice-off.svg'
      : '/images/icon/call-voice.svg'});
  `}
`;

export const VideoAction = styled(ActionButton)`
  ${({ muted }) => css`
    background-color: #fff;
    background-image: url(${muted
      ? '/images/icon/call-video-off.svg'
      : '/images/icon/call-video.svg'});
  `}
`;

export const Top = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const RemoteUserName = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;

  display: flex;
  align-items: center;
  justify-content: center;

  ${ActionButton} {
    width: 2rem;
    height: 2rem;
    background-size: 1rem;
    cursor: auto;

    &:first-of-type {
      margin-left: 1rem;
      margin-right: 0.5rem;
    }
  }
`;

export const MeInfo = styled.div`
  position: relative;
  margin-right: 0;
  margin-left: auto;
`;

export const MeName = styled.h2`
  color: #fff;
  font-size: 1.1rem;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  padding: 1em;
`;

export const DisconnectedUser = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  color: #333;
  font-size: 3rem;
  z-index: 2;
`;

export const Icon = styled.img`
  width: 20px;
  height: auto;
`;

export const Disconnected = styled.div`
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em;
  cursor: pointer;
  gap: 3em;
  color: #fff;
  font-size: 18px;
  font-family: PF Handbook Pro;
  text-transform: capitalize;
  width: 100%;
  margin: auto;
  border-radius: 26px;
  background: linear-gradient(180deg, #f33060 0%, #ff4875 100%);
  backdrop-filter: blur(7.64222526550293px);
  transition: all 0.3s ease;
  flex-direction: column;
  text-align: center;
  min-height: 100%;
  svg {
    font-size: 32px;
  }
  &:hover {
    opacity: 0.8;
    display: flex;
    align-items: center;
    font-size: 0px;
    scale: 1.2;

    svg {
      font-size: 28px;
    }
  }
`;

export const EnterMeeting = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
  cursor: pointer;
  gap: 3em;
  color: #fff;
  font-size: 18px;
  font-family: PF Handbook Pro;
  text-transform: capitalize;
  width: 100%;
  margin: auto;
  border-radius: 0px;
  background: linear-gradient(180deg, #2dd25b 0%, #35f253 100%);
  backdrop-filter: blur(7.64222526550293px);
  transition: all 0.3s ease;

  svg {
    font-size: 25px;
  }
  &:hover {
    display: flex;
    align-items: center;
    border: 1px solid #35f253;
    color: #35f253;
    background: transparent;
  }
`;
