import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    min-height: 100vh;
    background-color: ${theme.plans.background};
    padding: 6rem 2rem;
`};
`

export const Hgroup = styled.div`
  text-align: center;
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  margin-bottom: 7rem;
`

export const Title = styled.h1`
  font-size: 3.3rem;
`

export const Description = styled.p`
  font-size: 1.7rem;
  line-height: 2.6rem;
`

export const Plans = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10rem;
`

export const PlanTitle = styled.h2`
  font-size: 1.5rem;
`

export const PlanHead = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`

export const WrapHead = styled.div``

export const PlanCurrency = styled.p`
  font-size: 1.4rem;
  `
export const PlanDescription = styled.p`
  font-size: 1.4rem;
  opacity: 0.8;

`

export const PlanPrice = styled.p`
  font-size: 3.6rem;
  font-weight: 600;
`

export const PlanButton = styled.button``
export const PlanFeatures = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 1.6rem;
`
export const PlanFeature = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const PlanFeatureIcon = styled.div``
export const PlanFeatureText = styled.p`
  font-size: 1.3rem;
`

export const Plan = styled.div`
  ${({ theme, isPrincipal }) => css`
    width: 28%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    background-color: ${isPrincipal ? theme.white : theme.plans.backgroundPlan};
    padding: ${isPrincipal ? '4.5rem' : '3.5rem'} 2.5rem;
    margin: ${isPrincipal ? '-2rem' : ''} 0;
    border-radius: ${isPrincipal ? '2rem' : ''};

    &:first-of-type {
      border-top-left-radius: 2rem;
    }
    
    &:last-of-type {
      border-top-right-radius: 2rem;
    }

    ${isPrincipal && css`
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    `}

    ${!isPrincipal && css`
      ${PlanTitle}, ${PlanDescription}, ${PlanPrice}, ${PlanFeatureText}, ${PlanCurrency}, svg {
        color: ${theme.white};
      }
    `}

  `}
`

export const Testimonial = styled.div`
  ${({ theme }) => css`
    background-image: url(/images/backgrounds/pricing-page.png);
    background-repeat: no-repeat;
    background-size: cover;
    color: ${theme.white};
    padding: 10rem 8rem;
    border-radius: 3rem;

    max-width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  `}
`

export const TestimonialTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  `

export const TestimonialDescription = styled.p`
  font-size: 2.4rem;
  font-weight: 600;
  max-width: 70%;
  line-height: 3.6rem;
`

export const TestimonialAuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const TestimonialAuthorName = styled.span`
  font-weight: 600;
  font-size: 1.8rem;
`

export const TestimonialAuthorJobTitle = styled.p`
  font-weight: 300;
  font-size: 1.4rem;
`