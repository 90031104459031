import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 7rem 3.5rem 11rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VSpace = styled.div`
  ${({ $space }) => css`
    margin-bottom: ${$space}px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 125px;
  justify-content: space-between;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    font-size: 5rem;
    margin-bottom: 0.3em;
    color: #88a2af;
  }
`;

export const Logo = styled.img.attrs({
  src: '/images/bluemed/logo-inline.svg',
})`
  margin-bottom: 3rem;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 21px;
    font-style: normal;
    margin-bottom: 3rem;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    color: ${theme.colors.gray};
    font-family: ${theme.font.regular};
  `}
`;

export const Subtitle = styled.h2`
  color: #d6d6d6;
  text-align: center;
  font-weight: normal;
  font-size: 1.2rem;
  margin-bottom: 4.3rem;
`;
