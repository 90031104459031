import { animated } from 'react-spring';

import styled, { css, keyframes } from 'styled-components'
import { rgba } from 'polished'

export const Container = styled.div`
  position: relative;
  height: 100vh;
`

export const MaskFace = styled.div`
  position: absolute;
  z-index: 2;

  top: 0;
  left: 0;
  
  width: 100vw;
  height: 100vh;

  &:before {
    content: '';
    background: transparent linear-gradient(0deg, #010A1400 0%, #000000 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 15rem;
  }
`


const scanAnimation = keyframes`
 0% {
    background-position: top;
  }
  50% {
    background-position: center;
  }
  100% {
    background-position: top;
  }
`;

const topToBottom = keyframes`
 0% {
    top: 5rem;
  }
  50% {
    top: calc(100% - 6rem);
  }

  100% {
    top: 5rem;
  }
`;

export const Scan = styled.div`
  width: 70%;
  height: 37rem;

  position: absolute;
  top: 50%;
  left: 50%;

  background-color: ${rgba("#000", 0.3)};
  transform: translate(-50%, -50%);
  border-radius: 20%;
  
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20%;
    /* background: transparent linear-gradient(180deg, #28E8FC00 0%, #28E8FC 100%); */
    background-size: 100% 400%;
    opacity: 0.33;
    /* animation: ${scanAnimation} 1s linear infinite; */
  }

  &:after {
    content: "";
    width: calc(100% - 6px);
    height: 0.6rem;
    background-color: #28E8FC;
    
    position: absolute;
    top: 5rem;
    left: 3px;
    animation: ${topToBottom} 4s linear infinite;
    box-shadow: 0px 0px 60px #05FF65;
  }
`


export const Confirmed = styled.div`
  width: 70%;
  height: 37rem;

  position: absolute;
  top: 50%;
  left: 50%;

  background-color: ${rgba("#000", 0.7)};
  transform: translate(-50%, -50%);
  border-radius: 10%;
  
  border: 0.4rem dashed #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-size: 2.1rem;
  color: #28E8FC;
  text-transform: uppercase;
  font-weight: bold;
  gap: 2rem;
`


export const ScanTop = styled.div`
  position: absolute;
  top: 0;
  width: 100%;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 8.2rem;
    height: 8.2rem;
    background-image: url('/images/bluemed/sides.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:before {
    left: 0;
    transform: rotate(180deg);
  }
  
  &:after {
    right: 0;
    transform: rotate(270deg);
  }

`


export const ScanBottom = styled(ScanTop)`
  top: calc(100% - 8.2rem);
  transform: translateY(-100%);

  &:before {
    left: 0;
    transform: rotate(90deg);
  }
  
  &:after {
    right: 0;
    transform: rotate(0deg);
  }
`

export const Particles = styled(animated.img).attrs({
  src: '/images/bluemed/particle-icon.svg'
})`
  position: absolute;
  width: 11px;
  height: 10px;
  transition: 0.3s;
`

export const BoxTop = styled.div`
  position: absolute;
  top: 4rem;
  z-index: 3;

  border: 0.2rem dashed #fff;
  border-radius: 1.7rem;
  padding: 1rem 2.5rem 1rem 1.3rem;
  text-transform: uppercase;

  color: #fff;
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  gap: 2rem;

  left: 50%;
  transform: translateX(-50%);
`


export const Icon = styled.img``

export const BoxBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  padding: 2rem;

  transition: 0.3s;
  display: flex;

  ${({ completed }) => css`
    height: ${completed ? '20rem' : '10rem'};
  `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    background: transparent linear-gradient(180deg, #000000 0%, #545454 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 51px 51px 0px 0px;
    z-index: 1;
    opacity: 0.5;

    width: 100%;
    height: 100%;
    transition: 0.3s;
  }
`

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;

  z-index: 3;

  background-color: ${rgba("#28e8fc", 0.2)};

  height: 6rem;
  width: 6rem;
  border-radius: 50%;

  ${({ completed }) => completed && css`
    top: 0;
  `}
`

export const Button = styled.div`
  /* background: #010A0545 0% 0% no-repeat padding-box; */

  width: 100%;
  margin: 4rem auto;
  border-radius: 37px;

  position: relative;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: space-between;
  
  img {
    height: 2.6rem;
    margin: 2rem;
  }

  button {
    background: transparent linear-gradient(180deg, #00E7FF 0%, #0089FF 100%) 0% 0% no-repeat padding-box;
    font-size: 1.5rem;
    width: 100%;
  }

`