import AppointmentCard from '../../../../components/Appointment';
import Header from '../../../../components/Header';
import PrescriptioMacroView from '../../../../components/PrescriptioMacroView';
import ShopPartners from '../../../../components/ShopPartners';
import * as S from './styles';

const Appointment = () => {
  return (
    <>
      <Header />
      <S.Container>
        <S.Title>
          <S.Icon src='/images/icon/account/appointments-grey.svg' />{' '}
          Agendamento
        </S.Title>
        <AppointmentCard />
        <PrescriptioMacroView />
        <ShopPartners />
      </S.Container>
    </>
  );
};

export default Appointment;
