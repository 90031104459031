import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Feedbacks from '../../components/Feedbacks';
import { API_URL_MIDDLEWARE, URL_TO_TERM } from '../../env';
import { httpService } from '../../services/axios';
import * as S from './styles';
import { isNecessarySignatureAndPayment } from '../../rules';

let interval = null;

const Terms = () => {
  const { term } = useParams();
  const navigate = useNavigate();
  const iframe = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [anamnese, setAnamnese] = useState({});
  const [isSigned, setIsSigned] = useState(false);

  const handleGetTermInfo = async () => {
    try {
      setIsLoading(true);
      Swal.fire({
        title: 'Carregando...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const anamnesesId = localStorage.getItem('anamnesesId');

      const { data: response } = await httpService.get(
        `${API_URL_MIDDLEWARE}/anamneses/${anamnesesId}`,
      );
      Swal.close();
      setAnamnese(response);
    } catch (error) {
      Swal.fire({
        title: `Erro ao localizar a anamnese, tente novamente.`,
        icon: 'warning',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyTerm = useCallback(async () => {
    try {
      const documentId =
        term === 'term-of-responsibility'
          ? anamnese?.attributes?.term?.idTermOfResponsibility
          : anamnese?.attributes?.term?.idTermOfVeracity;
      const { data: response } = await httpService.get(
        `${API_URL_MIDDLEWARE}/documents/${documentId}`,
      );

      let documentIsSigned = response?.status === 'signed';

      if(isNecessarySignatureAndPayment){
        documentIsSigned = true;
      }

      setIsSigned(documentIsSigned);

      if (documentIsSigned) {
        await httpService.put(
          `${API_URL_MIDDLEWARE}/anamneses/${anamnese?.id}`,
          {
            term: {
              id: anamnese.attributes.term.id,
              [term === 'term-of-responsibility'
                ? 'termOfResponsibilitySigned'
                : 'termOfVeracitySigned']: true,
            },
          },
        );

        localStorage.setItem(URL_TO_TERM[term], true);

        setTimeout(() => {
          navigate('/terms-and-accept');
        }, 3000);
      }
    } catch (error) {
      console.log(error)
      setIsSigned(false);
      Swal.fire({
        title: `Erro ao localizar o documento, tente novamente.`,
        icon: 'warning',
      });
    }
  }, [anamnese, term]);

  useEffect(() => {
    handleGetTermInfo();
  }, [term]);

  const transformHttpToHttps = (url) => {
    return url?.replace('http://', 'https://');
  };

  useEffect(() => {
    if (anamnese?.attributes?.term?.id) {
      interval = setInterval(() => {
        handleVerifyTerm();
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    }
  }, [anamnese]);

  return (
    <>
      <Feedbacks
        show={isSigned}
        title='Assinado com sucesso!'
        description={() => (
          <>
            Você pode baixar o  seu documento original e assinado <br />{' '}
            na área do usuário.
          </>
        )}
      />
      <S.Container $blured={false}>
        <S.Contents>
          <S.Iframe
            src={`${transformHttpToHttps(
              term === 'term-of-responsibility'
                ? anamnese?.attributes?.term?.linkTermOfResponsibility
                : anamnese?.attributes?.term?.linkTermOfVeracity,
            )}?lang=pt-br`}
            ref={iframe}
          />
        </S.Contents>
      </S.Container>
    </>
  );
};

export default Terms;
