import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import MacroAppointments from "../../../components/MacroAppointments";
import * as S from "./styles";
import { httpService } from "../../../services/axios";

const Orders = () => {
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    getAppointments();
  }, []);

  const getAppointments = async () => {
    try {
      setLoading(true);
      const { data } = await httpService.get(`/users/schedule/appointments`);
      setAppointments(data);
    } catch (error) {
      console.error("Error get appointments", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <S.Container>
        <S.Title>
          <S.Icon src="/images/icon/account/appointments-grey.svg" /> Meus
          Agendamentos
        </S.Title>
        {loading && <p>Carregando...</p>}
        {appointments?.length === 0 ? (
          <p>Nenhum agendamento encontrado</p>
        ) : (
          appointments?.map((appointment, index) => (
            <MacroAppointments
              key={index}
              date={appointment?.attributes?.scheduleDate}
              doctorName={
                appointment?.attributes?.doctor?.data?.attributes?.name
              }
              crm={appointment.attributes?.doctor?.data?.attributes?.crm}
            />
          ))
        )}
      </S.Container>
    </>
  );
};

export default Orders;
