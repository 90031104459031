// styles.ts
import styled from 'styled-components';

export const PrescriptionCard = styled.div`
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  position: relative;
`;

export const PrescriptionHeader = styled.div`
  background: #007bff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  margin: 0;
`;

export const DownloadButton = styled.button`
  background: #e0e0e0;
  border: none;
  border-radius: 15px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  &:hover {
    background: #c0c0c0;
  }
`;

export const PrescriptionBody = styled.div`
  padding: 20px;
`;

export const PrescriptionContent = styled.div`
  // Add styles for the content such as font size, color, etc.
`;

// Add other necessary styles...
