import * as yup from "yup";

export const schema = yup
  .object({
    email: yup
      .string()
      .email("Insira um email válido")
      .max(100, "Email muito longo...")
      .required("Oops, parece que você não adicionou um email..."),
    password: yup
      .string()
      .min(5, "Humm, a senha está muito curta...")
      .max(30, "Senha muito longa...")
      .required("Oops, parece que você não adicionou a sua senha..."),
  })
  .required();
