import { IoMdCheckmark } from 'react-icons/io'
import Button from '../../components/Button'
import * as S from './styles'

const SelectPlan = () => {

  const plans = [
    {
      id: 'start',
      name: 'Start',
      price: 0,
      currency: 'BRL',
      features: [
        {
          id: 'production-of-video',
          name: 'Primeiros Passos',
          description: 'Crie 1 vídeo por mês',
          available: true
        },
        {
          id: 'video-duration',
          name: 'Duração Ideal',
          description: 'Até 10 minutos por vídeo',
          available: true
        },
        {
          id: 'languages-available',
          name: 'Línguas Básicas',
          description: 'Inglês, Português, Espanhol',
          available: true
        },
        {
          id: 'background-music',
          name: 'Sem Música de Fundo',
          description: 'Não disponível neste plano',
          available: false
        },
        {
          id: 'notifications',
          name: 'Notificações whatsapp',
          description: 'Não disponível neste plano',
          available: false
        }
      ]
    },
    {
      id: 'scale',
      name: 'Scale ✨',
      price: 25,
      currency: 'BRL',
      features: [
        {
          id: 'production-of-video',
          name: 'Máximo Rendimento',
          description: 'Produza até 4 vídeos incríveis por mês',
          available: true
        },
        {
          id: 'video-duration',
          name: 'Mais Tempo para Criar',
          description: 'Desfrute de até 20 minutos por vídeo',
          available: true
        },
        {
          id: 'languages-available',
          name: 'Línguas Ilimitadas',
          description: 'Acesso a todas as línguas no Amazon Polly',
          available: true
        },
        {
          id: 'background-music',
          name: 'Música Personalizada',
          description: 'Adicione até 5 trilhas exclusivas por mês',
          available: true
        },
        {
          id: 'notifications',
          name: 'Notificações whatsapp',
          description: 'Receba notificações via whatsapp',
          available: false
        }
      ]
    },
    {
      id: 'growth',
      name: 'Growth',
      price: 35,
      currency: 'BRL',
      features: [
        {
          id: 'production-of-video',
          name: 'Produção Ilimitada',
          description: 'Crie vídeos sem limites todo mês',
          available: true
        },
        {
          id: 'video-duration',
          name: 'Mais Tempo para Detalhes',
          description: 'Até 20 minutos para aperfeiçoar cada vídeo',
          available: true
        },
        {
          id: 'languages-available',
          name: 'Multilíngue',
          description: 'Acesso irrestrito às línguas no Amazon Polly',
          available: true
        },
        {
          id: 'background-music',
          name: 'Trilha Sonora Sem Limites',
          description: 'Adicione quantas músicas quiser',
          available: true
        },
        {
          id: 'notifications',
          name: 'Notificações VIP',
          description: 'Receba atualizações direto no seu Whatsapp',
          available: true
        }
      ]
    }
  ]

  return (
    <S.Container>
      <S.Hgroup>
        <S.Title>Escolha seu ritmo, descubra suas possibilidades</S.Title>

        <S.Description>
          Transforme suas ideias em realidade com os nossos planos adaptáveis. O Clips Composer é sua ferramenta para criar conteúdo envolvente com facilidade e eficiência. Seja para começar seu projeto ou para levar sua produção de vídeo ao próximo nível, temos o plano certo para você.
        </S.Description>
      </S.Hgroup>

      <S.Plans>
        {plans.map((plan) => {
          const isScale = plan.id === 'scale'
          return (
            <S.Plan key={plan.id} isPrincipal={isScale}>
              <S.PlanTitle>{plan.name}</S.PlanTitle>

              <S.PlanHead>
                <S.PlanPrice>
                  {plan.price === 0 ? 'Grátis' : `${plan.currency === 'BRL' ? 'R$ ' : '$'} ${plan.price}`}
                </S.PlanPrice>
                <S.WrapHead>
                  <S.PlanCurrency>{plan.currency}</S.PlanCurrency>
                  <S.PlanDescription>Pago mensalmente</S.PlanDescription>
                </S.WrapHead>

              </S.PlanHead>

              <Button variant={isScale ? 'blue' : 'gray'}>Selecionar este plano</Button>

              <S.PlanFeatures>
                {plan.features.map((feature) => feature.available && (
                  <S.PlanFeature key={feature.id}>
                    <S.PlanFeatureIcon>
                      <IoMdCheckmark size={19} />
                    </S.PlanFeatureIcon>
                    <S.PlanFeatureText>{feature.description}</S.PlanFeatureText>
                  </S.PlanFeature>
                ))}
              </S.PlanFeatures>
            </S.Plan>
          )
        })}
      </S.Plans>

      <S.Testimonial>
        <S.TestimonialTitle>Íncrivel, me ajudou a ter tempo de vida.</S.TestimonialTitle>
        <S.TestimonialDescription>
          “O Clips Composer me ajudou a ter mais tempo para mim e para minha família. Antes eu passava horas editando meus vídeos, agora eu consigo fazer tudo em poucos minutos.”
        </S.TestimonialDescription>
        <S.TestimonialAuthorWrapper>
          <S.TestimonialAuthorName>Iris Almeida</S.TestimonialAuthorName>
          <S.TestimonialAuthorJobTitle>Usuário do Clips Composer</S.TestimonialAuthorJobTitle>
        </S.TestimonialAuthorWrapper>
      </S.Testimonial>
    </S.Container>
  )
}

export default SelectPlan
