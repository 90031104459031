// MacroAppointments.js or MacroAppointments.jsx
import React from "react";
import * as S from "./styles";

const MacroAppointments = ({
  date,
  doctorName,
  doctorImage,
  crm,
  speciality,
}) => {
  const dataAppointments = new Date(date);

  const data = dataAppointments.toISOString().split("T")[0];
  const horario = dataAppointments.toISOString().split("T")[1].split(".")[0];

  return (
    <S.AppointmentCard>
      <S.AppointmentInfo>
        <S.DateAndTime>
          <S.Icon
            src="/images/icon/account/user-menu.svg"
            alt="Calendar Icon"
          />
          {`${data} às ${horario}`}
        </S.DateAndTime>
        <S.DoctorInfo>
          <S.DoctorImage
            src="/images/icon/account/profile-grey.svg"
            alt={`Dr(a). ${doctorName}`}
          />
          <S.DoctorDetails>
            <S.DoctorName>{doctorName}</S.DoctorName>
            <S.Speciality>
              <S.Icon
                src="/images/icon/account/user-menu.svg"
                alt="Calendar Icon"
              />
              <S.SpecialityText>{`CRM ${crm}`}</S.SpecialityText>
              <S.Icon
                src="/images/icon/account/user-menu.svg"
                alt="Calendar Icon"
              />
              {/* <S.SpecialityText>{speciality}</S.SpecialityText> */}
            </S.Speciality>
          </S.DoctorDetails>
        </S.DoctorInfo>
      </S.AppointmentInfo>
      {/* <S.ArrowIcon>→</S.ArrowIcon> */}
    </S.AppointmentCard>
  );
};

export default MacroAppointments;
