import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 15px 0px rgba(147, 174, 186, 0.22);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 120px;
  padding: 0.7rem 1rem;
  gap: 1.5rem;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const Icon = styled.img.attrs({
  src: '/images/icon/roundedWhite.svg',
  alt: 'Icon',
})`
  height: 60px;
`;
