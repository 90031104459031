import { object, ref, string } from "yup";
import * as yup from "yup";

export const Step3Schema = object({
  cep: yup.string().required("Oops, parece que você não adicionou seu cep..."),
  number: yup
    .string()
    .required("Oops, parece que você não adicionou o número da sua casa..."),
  street: yup
    .string()
    .required("Oops, parece que você não adicionou sua rua..."),
  neighborhood: yup
    .string()
    .required("Oops, parece que você não adicionou o bairro..."),
  city: yup
    .string()
    .required("Oops, parece que você não adicionou a cidade..."),
  state: yup
    .string()
    .required("Oops, parece que você não adicionou o estado..."),
});
