import * as S from "./styles";
import { APPOINTMENT, whatsLink, whatsNumber } from "../../../../CONSTANTS";
import Button from "../../../../components/Button";
import { formatPhoneNumber } from "../../../../utils/formatPhoneNumber";

export function WhatsAppContact({ setType }) {
  return (
    <S.Body>
      <S.TopWrapper>
        <>
          <S.TopLeft>
            <S.Description>
              Por favor, contate nos para agendar um horário.Também em caso de dúvidas, entre em contato com nossa equipe de suporte pelo WhatsApp.
            </S.Description>
            <S.YSpace />
            <S.WhatsContent
              onClick={() => window.open(whatsLink)}
            >
              <S.WhatsNumber>{formatPhoneNumber(whatsNumber)}</S.WhatsNumber>
              <S.WhatsLogo
                width={45}
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
              />
            </S.WhatsContent>
          </S.TopLeft>
        </>
      </S.TopWrapper>
    </S.Body>
  );
}
