import { useParams } from "react-router-dom";
import Chat from "../../components/Chat";
import { httpService } from "../../services/axios";
import { useCallback } from "react";

const MedicalVision = () => {
  const params = useParams()


  const updateSchedule = useCallback(async (scheduleDate, status, appointment) => {
    await httpService.put(`/medical-appointment/${appointment}`, {
      ...(scheduleDate && { scheduleDate }),
      anamnesis: localStorage.getItem('anamnesesId'),
      status,
      doctor: params.doctor
    })
  }, [params])

  return <Chat name={'Nome do medico'} roomName={params.room} appointment={params.appointment} updateSchedule={updateSchedule} />
}

export default MedicalVision
