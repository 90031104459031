import { useEffect, useState } from "react";
import AttachDocuments from "../../../components/AttachDocuments";
import Header from "../../../components/Header";
import * as S from "./styles";
import { httpService } from "../../../services/axios";
import Swal from "sweetalert2";
import { getFromLocalStorage, saveToLocalStorage } from "../../../utils/localStorage";

const Documents = () => {
  const [list, setList] = useState([]);

  const handleGetDocuments = async () => {
    try {
      const cacheData = getFromLocalStorage("@bluemed:user-documents", 0.5)

      if(!cacheData){
        Swal.fire({
          title: 'Buscando arquivos...',
          didOpen: () => {
            Swal.showLoading();
          },
          allowOutsideClick: false,
        });
        const { data } = await httpService.get("/medical-appointment/documents");

        const listInfo = data.map((item) => ({
          title: item.attributes.title,
          id: item.id,
        }));

        saveToLocalStorage("@bluemed:user-documents",  listInfo)
        setList(listInfo);
      } else {
        setList(cacheData ?? []);
      }

    } catch (error) {
      console.log("Erro ao buscar documentos do usuário");
    } finally {
      Swal.close();
    }
  };
  useEffect(() => {
    handleGetDocuments();
  }, []);

  return (
    <>
      <Header />
      <S.Container>
        <S.Title>
          <S.Icon src="/images/icon/account/files-grey.svg" /> Seus documentos
        </S.Title>

        {list?.map((item) => (
          <AttachDocuments
            key={`attach-${item.id}`}
            title={item.title}
            id={item.id}
          />
        ))}

        {list && list.length <= 0 ? <S.DocumentTitle>Você ainda não possui nenhuma prescrição.</S.DocumentTitle> : <></>}
      </S.Container>
    </>
  );
};

export default Documents;
