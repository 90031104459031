import VideoChat from "../../components/VideoChat";

const VideoTest = () => {

  return (
    <VideoChat />
  );

}

export default VideoTest