import { useSearchParams, useNavigate, useParams } from 'react-router-dom'
import Feedbacks from '../../components/Feedbacks'
import * as S from './styles'
import { useCallback, useEffect, useRef } from 'react'
import { httpService } from '../../services/axios'
import { API_URL_MIDDLEWARE } from '../../env'
import generateUUID from '../../utils/generateUUID'

const paymentProvider = {
  card: 'stripe',
  pix: 'asaas'
}
const PaymentSuccess = () => {
  const [queryParams] = useSearchParams()
  const { method } = useParams()
  const navigate = useNavigate()
  const isAnamneseUpdated = useRef(false);


  const payment_intent = queryParams.get('payment_intent')
  const payment_intent_client_secret = queryParams.get('payment_intent_client_secret')
  const redirect_status = queryParams.get('redirect_status')

  const handleUpdateAnamnese = useCallback(async () => {
    try {
      const anamnesesId = localStorage.getItem('anamnesesId')

      await httpService.put(`${API_URL_MIDDLEWARE}/anamneses/${anamnesesId}`, {
        paymentId: payment_intent,
        paymentClient: payment_intent_client_secret,
        paymentProvider: paymentProvider[method]
      })

      const promiseAwait = async () => new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve()
        }, 3000)
      })

      await promiseAwait()
      
      const uuid = generateUUID()
      navigate(`/medical-appointment/${uuid}`)
    } catch (error) {
      console.log("error", error)
    }
  }, [isAnamneseUpdated, payment_intent, payment_intent_client_secret, navigate]);

  useEffect(() => {
    if (redirect_status === 'succeeded') {
      handleUpdateAnamnese()
    }
    localStorage.removeItem('@bluemed:medical-appointment-id')
  }, [redirect_status, isAnamneseUpdated, handleUpdateAnamnese]);


  return (
    <S.Container>
      <Feedbacks
        show={true}
        type='terciary'
        title='Pagamento Aprovado'
        description={() => (<>Aguarde enquanto te redirecionamos <br /> para falar com nossos médicos.</>)}
      />
    </S.Container>
  )
}

export default PaymentSuccess
