// styles.ts
import styled from 'styled-components';

export const SliderContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; // for Firefox
  &::-webkit-scrollbar {
    display: none; // for Chrome, Safari, and Opera
  }
`;

export const ProductCard = styled.div`
  flex: 0 0 auto; // for the flexbox slider
  width: 300px; // Adjust the width as needed
  margin-right: 20px;
  scroll-snap-align: start;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const ProductImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

export const ProductInfo = styled.div`
  margin-top: 20px;
`;

export const ProductTitle = styled.h3`
  font-size: 1rem;
  color: #333;
`;

export const ProductPrice = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin: 10px 0;
`;

export const BuyButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
`;

// ... Add styles for other elements like delivery options, etc.

export const DeliveryOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  font-size: 0.9rem;
  color: #333;
`;

export const DeliveryIcon = styled.span`
  // Placeholder for the icon, you might want to replace it with an actual icon
  margin-right: 5px;
`;
