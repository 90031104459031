import { object, ref, string } from "yup";
import * as yup from "yup";

export const Step4Schema = object({
  document: yup
    .string()
    .required("Oops, parece que você não adicionou o documento..."),
  docType: yup
    .string()
    .required("Oops, parece que você não selecionou o tipo do documento..."),
});
