import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ $show, $fullPage }) => css`
    transition: 0.3s;
    position: fixed;
    height: fit-content;
    bottom: -100%;
    width: 100%;
no-repeat padding-box;
    transition: 1s;
    visibility: hidden;
    z-index: -1;
    overflow: auto;
    padding-bottom: 0rem;
    max-width: 500px;

    ${
      $show &&
      css`
        z-index: 2;
        visibility: visible;
        bottom: 0;
        height: 100%;
        transition: 0.5s;

        ${!$fullPage &&
        css`
          padding-top: 25rem;
          background: rgb(244, 249, 255);
          background: linear-gradient(
            0deg,
            rgba(244, 249, 255, 1) 0%,
            rgba(244, 249, 255, 0) 100%
          );
          backdrop-filter: blur(10px);
        `}
      `
    }
  `}
`;

export const Container = styled.div`
  ${({ $fullPage }) => css`
    display: flex;

    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    position: relative;
    padding-top: ${$fullPage ? '25rem' : '5rem'};

    ${!$fullPage &&
    css`
      border-radius: 21px 21px 0px 0px;
      box-shadow: 0px -3px 45px #88a2af5c;
      width: 100%;
      height: 100%;
      padding-left: 10%;
      padding-right: 10%;
      background: var(
        --WhiteBluInverter,
        linear-gradient(180deg, #fff 0%, #f4f9ff 100%)
      );
    `}
  `}
`;

export const Group = styled.div`
  ${({ $show }) => css`
    display: flex;
    flex-direction: column;
    padding: 4rem 5rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 9px 21px #06626e3b;
    border-radius: 0px 0px 45px 45px;
    z-index: 1;
    top: -400%;
    opacity: 0;
    transition: 0.1s;

    ${$show &&
    css`
      top: 0;
      opacity: 1;
      transition: 0.5s;
    `}
  `}
`;

export const TitleGroup = styled.h1`
  ${({ theme }) => css`
    background: linear-gradient(172deg, #00afd3 0%, #045fa8 100%) no-repeat;
    background-size: cover;
    background-position: 0% 0%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 98%; /* 45.08px */
    font-family: ${theme.font.bold};
    box-sizing: content-box;
    padding-top: 1rem;
    padding-bottom: 1rem;
  `}
`;

export const DescriptionGroup = styled.p`
  ${({ theme }) => css`
    font-size: 2rem;
    line-height: 105%;
    font-weight: normal;
    font-weight: 400;
    color: ${theme.colors.gray};
    font-family: ${theme.font.regular};
  `}
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  position: relative;
`;

export const CloseHeader = styled.div`
  position: absolute;
  top: -3rem;
  right: -2rem;
  cursor: pointer;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 21px;
    font-style: normal;
    margin-top: 1em;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    color: ${theme.colors.gray};
    font-family: ${theme.font.regular};
  `}
`;

export const Socials = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
`;

export const ActionTitle = styled.p`
  ${({ theme }) => css`
    text-align: center;
    text-transform: uppercase;
    opacity: 0.5;
    letter-spacing: 0.1em;
    margin: 1em 0;
    font-size: 1.3rem;

    color: ${theme.colors.gray};
    font-family: ${theme.font.regular};
  `}
`;

export const Icons = styled.div`
  display: flex;
  gap: 2.5rem;
`;

export const IconSocialBtn = styled.div`
  display: flex;
  width: 332px;
  height: 60px;
  padding: 1em 1em 1em 2em;
  justify-content: space-between;
  align-items: center;
  border-radius: 120px;
  background: var(--White, #fff);
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.05);
  color: #878787;
  font-size: 20px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 700;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: none;
    background-color: #ffffff90;
  }
`;

export const Icon = styled.div`
  ${({ icon }) => css`
    background-image: url(${`/images/icon/${icon}.svg`});
    width: 5rem;
    height: 5rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 2.5rem;
  `}
`;

export const Form = styled.form`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${ActionTitle} {
    position: relative;

    &::before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: calc(30vw);
      height: 0.1rem;
      background-color: #dfedf4;
    }

    &:after {
      right: 0;
    }

    &:before {
      left: 0;
    }
  }
`;

export const Fields = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ForgotPassword = styled.a`
  text-align: right;
  font-size: 1.5rem;
  text-decoration: underline;
  color: #0198c7;
  font-weight: 200;
  margin-top: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;

  button {
    flex: 1;
  }
`;

export const Close = styled.div`
  background-color: #dfedf4;
  border: 1px solid #dfedf4;
  min-width: 65px;
  min-height: 65px;
  height: 6.4rem;
  border-radius: 100%;
  background-image: url('/images/icon/arrow-bottom-blue.svg');
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.3s ease;

  &:hover {
    border: 1px solid #dfedf4;
    background-color: transparent;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: #a3a3a3;
  font-size: 1.8rem;
  margin-top: 1em;
  gap: 0.5rem;
`;

export const SignUp = styled.p`
  color: #646464;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.8rem;
`;
