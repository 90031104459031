import styled, { css } from 'styled-components'
import { rgba } from 'polished'

export const Icon = styled.img.attrs(({ icon = 'check-circle'}) => ({ src: `/images/icon/${icon}.svg`}))``

export const Title = styled.h2`
  ${({ theme }) => css`
    color: #31769B;
    font-size: 3rem;
    font-family: ${theme.font.black};
    line-height: 2.3rem;
  `}
`

export const Description = styled.p`
  ${({ theme }) => css`
    color: #5290AF;
    font-size: 2rem;
    line-height: 1.8rem;
    font-family: ${theme.font.bold};
  `};
`

export const Container = styled.div`
  ${({ $show, type }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${rgba('#ffffff', 1)};
    text-align: center;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    ${type === 'secondary' && css`
      background: transparent linear-gradient(180deg, #FCF2F2 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;

      ${Title} {
        color: #CC6161;
      }

      ${Description} {
        color: #B19C9C;
      }
    `}

    ${type === 'terciary' && css`
      background: transparent linear-gradient(180deg, #F2F8FC 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    `}

    ${$show && css`
      z-index: 5;
      opacity: 1;
    `}
  `}
`

export const Loading = styled.div``

export const LoadingIcon = styled.img.attrs({
  src: '/images/icon/loading.svg'
})``
