import styled from 'styled-components';

export const Feedback = styled.div`
  background: var(--BlueBg, linear-gradient(180deg, #00abd1 0%, #0260a9 100%));
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Seal = styled.img.attrs({
  src: '/images/icon/rounded.svg',
})`
  height: 3.2rem;
`;

export const Logo = styled.img.attrs({
  src: '/images/logo-bluemed-white.svg',
})`
  height: 3rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 3rem 2rem;
  gap: 1rem;
  opacity: 0.9;
  filter: grayscale(0.6);

  margin: 0 auto;
`;

export const Content = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 24px 24px 0px 0px;
  padding: 4rem 3rem;
  max-width: 100%;

  button {
    background: transparent linear-gradient(180deg, #2bb9d9 0%, #2c7bb7 100%) 0%
      0% no-repeat padding-box;
    font-size: 2.5rem;
    border-radius: 40px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;

export const SucessBox = styled.div`
  border-radius: 17px;
  border: 1px solid var(--BlurStroke, rgba(255, 255, 255, 0.3));
  background: var(
    --BlurStroke,
    radial-gradient(
      302.54% 163.59% at 0% 0%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    )
  );
  display: flex;
  width: fit-content;
  height: 38px;
  padding: 10px 1em;
  margin-bottom: 2rem;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff;
  font-family: 'PF Handbook Pro';
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  svg {
    font-size: 2rem;
  }
`;

export const Title = styled.h2`
  color: #fff;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-bottom: 1rem;
`;

export const SubTitle = styled.h6`
  color: var(--White, #fff);
  text-align: center;
  font-family: 'PF Handbook Pro';
  font-size: 21px;
  opacity: 0.7;
  font-style: normal;
  font-weight: 400;
  line-height: 107%;
  margin-bottom: 3rem;
`;

export const Description = styled.p`
  font-size: 2.1rem;
  color: #88a2af;
  line-height: 1.8rem;
  margin-bottom: 2rem;
`;

export const RedirectMessage = styled.p`
  color: var(--White, #fff);
  font-size: 18px;
  line-height: 1.8rem;
  margin-bottom: 2rem;
`;

export const Actions = styled.div``;

export const Icon = styled.img.attrs({
  src: `/images/icon/check-circle.svg`,
})`
  height: 2.3rem;
`;

export const Copyright = styled.div`
  text-align: center;
  color: #fff;
  font-size: 2.1rem;
  padding: 2rem;
  margin-top: 5rem;
`;

export const SuccessBox = styled.div`
  border-radius: 17px;
  border: 1px solid var(--BlurStroke, rgba(255, 255, 255, 0.3));
  background: var(
    --BlurStroke,
    radial-gradient(
      302.54% 163.59% at 0% 0%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    )
  );
  display: flex;
  width: fit-content;
  height: 38px;
  padding: 10px 1em;
  margin-bottom: 2rem;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff;
  font-family: 'PF Handbook Pro';
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  svg {
    font-size: 2rem;
  }
`;
