
import * as yup from "yup";
import { object, ref, string } from "yup";
import { httpService } from "../../../../services/axios";
import { API_URL_MIDDLEWARE } from "../../../../env";

const isEmailTaken = async (email) => {
  try {
    const { data } = await httpService.get(
      `${API_URL_MIDDLEWARE}/users/${email}`
    );
    return !!data;
  } catch (error) {
    return false;
  }
};

export const Step1Schema = object({
  email: string()
    .email("Insira um email válido")
    .max(100, "Email muito longo...")
    .test(
      "email-exists",
      "Este email já está cadastrado.",
      async function (value) {
        if (!value) return true; // Não faz a verificação se o campo estiver vazio
        const isTaken = await isEmailTaken(value);
        return !isTaken;
      }
    )
    .required("Oops, parece que você não adicionou um email..."),
  name: string()
    .max(150, "Nome muito longo...")
    .required("Oops, parece que você não adicionou um nome..."),
  password: string()
    .min(6, "Mínimo de 6 digitos na senha") // Validação de senha ajustada
    .max(30, "Senha muito longa...")
    .required("Oops, parece que você não adicionou a sua senha..."),
  confirmPassword: string()
    .oneOf([ref("password"), null], "As senhas não coincidem")
    .required("Você precisa confirmar a senha"),
  whatsapp: string()
    .required(
      "Oops, parece que você não adicionou um número para o whatsapp..."
    ),
});
