import React from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { CiFileOn, CiLogout } from 'react-icons/ci';
import { GoQuestion } from 'react-icons/go';
import { IoCalendarClearOutline } from 'react-icons/io5';
import { LuUser2 } from 'react-icons/lu';
import { RxDashboard } from 'react-icons/rx';
import Button from '../../components/Button';
import Header from '../../components/Header/';
import { API_URL_MIDDLEWARE, PROVIDER_NAME, TOKEN_NAME } from '../../env';
import * as S from './styles';
import { getFromLocalStorage, saveToLocalStorage } from '../../utils/localStorage';
import { httpService } from '../../services/axios';
import { differenceInDays, differenceInHours, differenceInMinutes, addHours, addMinutes } from 'date-fns';
import { TOLERANCE_SCHEDULE } from '../../CONSTANTS';
import { configSystem } from '../../CONFIG';
import { useUser } from '../../providers/UserProvider';
import { useQueue } from '../../providers/QueueProvider';

const Profile = () => {
  const { userInfo: { user } } = useUser();
  const { hasDoctorsAvailable } = useQueue();
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate("/profile/settings");
  };

  const handleDocumentsClick = () => {
    navigate("/profile/documents");
  };

  const handleAppointmentsClick = () => {
    navigate("/profile/orders");
  };

  const handleFaqClick = () => {
    navigate("/profile/faq");
  };

  const handleLogoutClick = () => {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(PROVIDER_NAME);
    navigate('/');
  };

  const SendToCall = (nextScheduler) =>{
    saveToLocalStorage('@bluemed:medical-appointment-id', nextScheduler);
    navigate(`/medical-appointment/${nextScheduler}`);
  }

  const handleQueueClick = async (nextScheduler) => {
    try {
        const response = await httpService.get(`${API_URL_MIDDLEWARE}/medical-appointment/${nextScheduler}/status`);
        const { scheduleDate, status } = response.data.data.attributes; // Supondo que a resposta contém um campo `scheduleDate`

        if(status === 'canceled') {
          Swal.fire({
            title: 'Atenção!',
            text: 'Sua ultima consulta foi encerrada, e o médico informou que você não necessita de uma prescrição. Deseja agendar uma nova consulta?',
            confirmButtonText: 'Ok',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'cancelar',
          }).then((result) => {
              if (result.isConfirmed) {
                handleNavigateIndications()
              }
          });
          return;
        }
        const now = new Date();
        const targetDate = new Date(scheduleDate); // Substitua pela sua data-alvo
        const diff = differenceInMinutes(new Date(scheduleDate), now);


        //ON TIME
        if(diff >= -TOLERANCE_SCHEDULE && diff <= TOLERANCE_SCHEDULE){
          if(configSystem.systemQueueService === false)
             Swal.fire({
              title: 'Aviso: Atendimento Indisponível no Momento',
              text: `Pedimos desculpas pelo transtorno, mas no momento não estamos disponíveis para atendimento. Por favor, reagende sua consulta para uma data posterior. Agradecemos sua compreensão e lamentamos qualquer inconveniente causado.`,
              icon: 'warning',
              confirmButtonText: 'OK'
            });
          SendToCall(nextScheduler)
        }
        //TO EARLY
        else if (diff > TOLERANCE_SCHEDULE) {
          const days = differenceInDays(targetDate, now);
          const hours = differenceInHours(targetDate, addHours(now, days * 24));
          const minutes = differenceInMinutes(targetDate, addMinutes(addHours(now, days * 24 + hours), 0));

          Swal.fire({
                title: 'Ainda é muito cedo para o agendamento',
                text: `Por favor, aguarde aproximadamente ${days !== 0 ? `${days} ${days > 1 ? ` dias,` : ` dia,`}` : ''} ${hours !== 0 ? `${hours} ${hours > 1 ? ` horas` : ' hora'}` : ''} ${minutes} ${minutes > 1 ? ' minutos' : ' minuto'} para acessar o agendamento. Você poderá entrar até ${TOLERANCE_SCHEDULE} minutos antes ou depois de seu agendamento.`,
                icon: 'warning',
                confirmButtonText: 'OK'
            });
        //LATE
        } else {
          Swal.fire({
            title: 'Perdeu seu atendimento?',
            text: `Deseja reagendar seu atendimento ${configSystem.systemQueueService && hasDoctorsAvailable ? 'ou entrar na fila de espera' : ''}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
              SendToCall(nextScheduler)
            } 
        });
        }
    } catch (error) {
        console.error('Erro ao verificar o agendamento:', error);
        Swal.fire({
            title: 'Erro',
            text: 'Ocorreu um erro ao verificar o agendamento. Tente novamente mais tarde.',
            icon: 'error',
            confirmButtonText: 'Entendi',
        });
    }
};

  const handleNavigateIndications = () => {
    navigate("/contra-indications");
  };

  const { nextScheduler } = user || {};
  const appointmentId = getFromLocalStorage('@bluemed:medical-appointment-id');


  return (
    <>
      {/* <AwarenessSession /> */}
      <Header />
      <S.Container>
        <S.Title>
          <RxDashboard />
          Menu de Usuário
        </S.Title>

        <S.Menu>
          {configSystem.systemInMaintenance ? <></> : nextScheduler || appointmentId ? (
            <Button
              variant='primary'
              size='large'
              $block
              icon={<IoCalendarClearOutline />}
              onClick={() => handleQueueClick(appointmentId ? appointmentId : nextScheduler)}
            >
              Acessar Atendimento
            </Button>
          ) : (
            <Button
              variant='grey'
              size='large'
              $block
              onClick={handleNavigateIndications}
            >
              Fale com o Médico
            </Button>
          )}
          <Button
            variant='grey'
            size='large'
            $block
            icon={<LuUser2 />}
            onClick={handleSettingsClick}
          >
            Meu Perfil
          </Button>

          <Button
            variant='grey'
            size='large'
            $block
            icon={<CiFileOn />}
            onClick={handleDocumentsClick}
          >
            Meus Documentos
          </Button>

          <Button
            variant='grey'
            size='large'
            $block
            icon={<IoCalendarClearOutline />}
            onClick={handleAppointmentsClick}
          >
            Consultas Agendadas
          </Button>

          <Button
            variant='grey'
            size='large'
            $block
            icon={<GoQuestion />}
            onClick={handleFaqClick}
          >
            Dúvidas frequentes
          </Button>

          <Button
            variant='grey'
            size='large'
            $block
            icon={<CiLogout />}
            onClick={handleLogoutClick}
          >
            Fazer logout
          </Button>
        </S.Menu>
      </S.Container>
    </>
  );
};

export default Profile;
