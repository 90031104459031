// ShopPartners.js or ShopPartners.jsx
import React from 'react';
import * as S from './styles';

// Mock data for featured products
const featuredProducts = [
  {
    imageUrl: '/path/to/product-image.jpg', // Replace with actual image path
    name: 'Canabidiol 20mg/ml Solução Oral 30ml Prati-Donaduzzi + Seringa Dosadora',
    price: 'R$254,90',
    deliveryOptions: [
      { type: 'Entrega Expressa', icon: '🚀' },
      { type: 'Retire a 2km', icon: '📍' },
    ],
    soldBy: 'Drogasil',
  },
  // ... Add more products as needed
];

const ShopPartners = () => {
  return (
    <S.SliderContainer>
      {featuredProducts.map((product, index) => (
        <S.ProductCard key={index}>
          <S.ProductImage src={product.imageUrl} alt={product.name} />
          <S.ProductInfo>
            <S.ProductTitle>{product.name}</S.ProductTitle>
            {product.deliveryOptions.map((option, index) => (
              <S.DeliveryOption key={index}>
                <S.DeliveryIcon>{option.icon}</S.DeliveryIcon>
                {option.type}
              </S.DeliveryOption>
            ))}
            <S.ProductPrice>{product.price}</S.ProductPrice>
            <S.BuyButton>Comprar</S.BuyButton>
          </S.ProductInfo>
        </S.ProductCard>
      ))}
    </S.SliderContainer>
  );
};

export default ShopPartners;
