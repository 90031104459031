import styled, { css } from 'styled-components';

export const Container = styled.h1`
  ${({ theme, type }) => css`
    ${type === 'black'
      ? css`
          background: linear-gradient(104deg, #6c6c6c 0%, #191919 100%) 0% 0%
            no-repeat;
        `
      : css`
          background: linear-gradient(172deg, #00abd1 0%, #0260a9 100%)
            no-repeat;
        `}

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: cover;
    background-position: 0% 0%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

    font-weight: normal;
    font-family: ${theme.font.bold};
    box-sizing: content-box;
    padding-top: 1rem;
    padding-bottom: 1rem;
  `}
`;
