import { FaChevronLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import Logo from '../Logo';
import * as S from './styles';

const Header = ({ noBackground, backAction = null }) => {
  const navigate = useNavigate();
  return (
    <S.Header $noBackground={noBackground}>
      <S.IconBox onClick={() => backAction ? backAction() : navigate(-1)}>
        <FaChevronLeft />
      </S.IconBox>
      <Logo height='2rem' />
    </S.Header>
  );
};

export default Header;
