// Appointment.js or Appointment.jsx
import React from 'react';
import * as S from './styles';

const AppointmentCard = ({
  dateTime,
  doctorName,
  doctorImage,
  crm,
  specialty,
  status,
  price,
  paymentMethod,
}) => {
  return (
    <S.AppointmentCard>
      <S.DateTimeInfo>
        {/* Icon should be replaced with an actual calendar icon */}
        <span>📅</span>
        <span>{dateTime}</span>
      </S.DateTimeInfo>
      <S.AppointmentInfo>
        <S.DoctorInfo>
          <S.DoctorImage src={doctorImage} alt={`Dr(a). ${doctorName}`} />
          <S.DoctorDetails>
            <S.Name>{doctorName}</S.Name>
            <S.Specialty>
              {/* Icons should be replaced with actual CRM and specialty icons */}
              <span>CRM {crm}</span>
              <span>{specialty}</span>
            </S.Specialty>
          </S.DoctorDetails>
        </S.DoctorInfo>
      </S.AppointmentInfo>
      <S.Status>{status}</S.Status>
      <S.ConsultationInfo>
        <span>Valor da consulta</span>
        <span>{price}</span>
      </S.ConsultationInfo>
      <S.PriceInfo>
        <span>Pagamento</span>
        <S.PaymentMethod>{paymentMethod}</S.PaymentMethod>
      </S.PriceInfo>
    </S.AppointmentCard>
  );
};

export default AppointmentCard;
