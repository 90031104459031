import styled from 'styled-components'

export const Container = styled.div`
  iframe {
    width: 100vw;
    height: 100vh;
    border: none;
  }
`


export const Feedback = styled.div`
  background: transparent linear-gradient(180deg, #F2F8FC 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
`

export const Seal = styled.img.attrs({
  src: '/images/icon/rounded.svg'
})`
  height: 3.2rem;
`

export const Logo = styled.img.attrs({
  src: '/images/logo-bluemed.svg'
})`
  height: 2rem;

`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  opacity: 0.9;
  filter: grayscale(0.6);
  padding: 10rem;

  margin: 0 auto;
`

export const Content = styled.div`
  text-align: center;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 50px #88A2AF9A;
  border-radius: 24px 24px 0px 0px;
  padding: 4rem 3rem;

  max-width: 90%;
  margin: auto auto 0;

  button {
    background: transparent linear-gradient(180deg, #2BB9D9 0%, #2C7BB7 100%) 0% 0% no-repeat padding-box;
    font-size: 2.5rem;
    padding: 2.7rem 0;
    border-radius: 40px;

    width: 100%;
    text-align: center;
    justify-content: center;
  }
`

export const Title = styled.h2`
  color: #31769B;
  font-weight: 600;
  font-size: 3rem;
  line-height: 2.4rem;
  margin-bottom: 2rem;
`

export const Description = styled.p`
  font-size: 2.1rem;
  color: #88A2AF;
  line-height: 1.8rem;
  margin-bottom: 2rem;
`

export const Actions = styled.div``

export const Icon = styled.img.attrs({
  src: `/images/icon/check-circle.svg`
})`
  height: 5.3rem;
  margin-bottom: 2rem;
` 

export const Copyright = styled.div`
  text-align: center;
  color: #31769B;
  font-size: 1.5rem;
  margin-top: 5rem;
`