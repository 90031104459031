// Questions.js or Questions.jsx
import React, { useState } from 'react';
import * as S from './styles';

// Mock data for FAQ items
const faqItems = [
  {
    question: 'Cannabis é legal?',
    answer:
      'A legalidade da cannabis varia conforme a legislação local e internacional.',
  },
  {
    question: 'Como faço solicitação junto à Anvisa?',
    answer:
      'Você deve seguir os procedimentos indicados no site oficial da Anvisa para solicitações.',
  },
  {
    question: 'Posso viajar com meus produtos?',
    answer:
      'Depende das regulamentações do país de destino e das regras das companhias aéreas.',
  },
  {
    question: 'O que é comprovante de vínculo?',
    answer:
      'É um documento que comprova sua relação com uma entidade ou organização.',
  },
  {
    question: 'Qual o prazo de entrega?',
    answer:
      'O prazo de entrega pode variar de acordo com o serviço de postagem ou transportadora escolhida.',
  },
  {
    question: 'Qual a validade da prescrição?',
    answer:
      'A validade da prescrição é determinada pela legislação e pelo médico que a emitiu.',
  },
];
const Questions = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    // If the same index is clicked, close the accordion item
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <S.AccordionContainer>
      {faqItems.map((item, index) => (
        <React.Fragment key={index}>
          <S.AccordionItem
            isActive={activeIndex === index}
            onClick={() => handleItemClick(index)}
          >
            <S.AccordionTitle>{item.question}</S.AccordionTitle>
            <S.Arrow isActive={activeIndex === index} />
          </S.AccordionItem>
          <S.AccordionContent isActive={activeIndex === index}>
            {item.answer}
          </S.AccordionContent>
        </React.Fragment>
      ))}
    </S.AccordionContainer>
  );
};

export default Questions;
