// AttachDocuments.js or AttachDocuments.jsx
import React, { useCallback } from 'react';
import { FiDownload } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { httpService } from '../../services/axios';
import * as S from './styles';

const AttachDocuments = ({ id }) => {
  const handleGetDocuments = useCallback(async () => {
    try {
      Swal.fire({
        title: 'Buscando arquivo para download...',
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
      });
      const { data } = await httpService.get(
        `/dashboard/download-receipt/${id}`,
      );

      const { file } = data;
      Swal.close();
      window.open(file);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  return (
    <S.DocumentCard onClick={handleGetDocuments}>
      <S.DocumentHeader>
        <S.DocumentTitle>Prescrição gerada</S.DocumentTitle>
        <S.DocumentContent>
          Baixar
          <FiDownload />
        </S.DocumentContent>
      </S.DocumentHeader>
      <S.ContentImage>
        <S.ThumbImage src='/images/thumb-prescription.png' alt='step1' />
      </S.ContentImage>
    </S.DocumentCard>
  );
};

export default AttachDocuments;
