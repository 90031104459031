export const lightTheme = {
  font: {
    regular: 'PF Handbook Pro Regular',
    italic: 'PF Handbook Pro Italic',
    extraThin: 'PF Handbook Pro Extra Thin',
    extraThinItalic: 'PF Handbook Pro Extra Thin Italic',
    thin: 'PF Handbook Pro Thin',
    thinItalic: 'PF Handbook Pro Thin Italic',
    mediumItalic: 'PF Handbook Pro Medium Italic',
    bold: 'PF Handbook Pro Bold',
    boldItalic: 'PF Handbook Pro Bold Italic',
    extraBold: 'PF Handbook Pro Extra Bold',
    extraBoldItalic: 'PF Handbook Pro Extra Bold Italic',
    black: 'PF Handbook Pro Black',
    blackItalic: 'PF Handbook Pro Black Italic',
    extraBlack: 'PF Handbook Pro Extra Black',
    extraBlackItalic: 'PF Handbook Pro Extra Black Italic',
    poppins: "'Poppins', sans-serif"
  },
  transition: '0.2s',
  colors: {
    primary: '#00AFD3',
    secondary: '#045FA8',
    gray: '#7B7B7B',
  },
  forms: {
    label: {
      fontSize: '1.3rem',
      padding: '0 0 0 1.6rem',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.8rem',
    },
    button: {
      text: '#fff',
    },
    error: '#bf1650',
    background: '#f2f2f2',
    borderColor: '#e5e5e5',
    active: '#fff',
    borderRadius: '6px',
    padding: '1.4rem 1.6rem',
    fontSize: '1.5rem',
    fontWeight: '400',
  },
  media: {
    mobile: `screen and (max-width: 768px)`,
    tablet: `screen and (min-width: 769px) and (max-width: 1024px)`,
    desktop: `screen and (min-width: 1025px)`
  },
  plans: {
    background: '#f6f6f6',
    backgroundPlan: '#212136',
  }
};

export const darkTheme = {
  background: '#121212', // Um fundo escuro
  white: '#121212', // "White" é agora uma cor escura
  text: '#e0e0e0', // Cor de texto clara para contraste
  primary: '#bb86fc', // Uma variante mais escura da cor primária
  transition: '0.2s', // Mantém a mesma transição
  colors: {
    blue: '#006699', // Uma versão ainda mais escura de azul
    blueClick: '#004466', // Uma versão extremamente escura para o click
  },
  forms: {
    label: {
      fontSize: '1.3rem',
      padding: '0 0 0 1.6rem',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.8rem',
    },
    button: {
      text: '#e0e0e0', // Texto claro para o botão
    },
    background: '#1e1e1e', // Fundo mais escuro para os formulários
    borderColor: '#3e3e3e', // Cor de borda mais escura
    active: '#2e2e2e', // Cor de atividade mais escura
    borderRadius: '6px', // Mantém o mesmo borderRadius
    padding: '1.4rem 1.6rem', // Mantém o mesmo padding
    fontSize: '1.5rem', // Mantém o mesmo fontSize
    fontWeight: '400', // Mantém o mesmo fontWeight
  }
};
