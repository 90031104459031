import styled, { css } from 'styled-components';

export const IconBox = styled.div`
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 1px;
  border-radius: 39px;
  background: #dfedf4;
  border: 1px solid #dfedf4;

  img {
    transition: transform 0.3s ease; // Apply the transition to the transform property of the img
  }

  &:hover {
    border: 1px solid #dfedf4;
    background: transparent;

    img {
      transform: translateX(-5px);
    }
  }
`;

export const LeftIcon = styled.img`
  width: 24px;
`;

export const Header = styled.div`
  ${({ $noBackground }) => css`
    width: 100%;
    margin: 0 auto;
    border-radius: 0;
    ${$noBackground ? '' : "background: var(--WhiteBlue, linear-gradient(180deg, #f2f8fc 0%, #fff 100%));" }
    ${$noBackground ? '' : "box-shadow: 0px 0px 30px 0px rgba(226, 236, 237, 0.5);" }
    padding: 1em 2em;

    display: flex;
    align-items: center;
    justify-content: space-between;
  `}` ;
