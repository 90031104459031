// styles.ts
import styled from 'styled-components';

export const AppointmentCard = styled.div`
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AppointmentInfo = styled.div`
  background: #007bff;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DateTimeInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const DoctorInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const DoctorImage = styled.img`
  border-radius: 50%;
  width: 80px; // Adjust based on your design
  height: 80px; // Adjust based on your design
  margin-right: 10px;
`;

export const DoctorDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Name = styled.h2`
  font-size: 1.2rem;
  color: #fff;
  margin: 0;
`;

export const Specialty = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
`;

export const Status = styled.div`
  font-size: 1rem;
  color: #4caf50; // Or the color that matches your design
  margin-bottom: 10px;
`;

export const ConsultationInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

export const PriceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const PaymentMethod = styled.div`
  font-size: 1rem;
  color: #333;
`;

// Add other necessary styles...
