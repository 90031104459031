// styles.ts
import styled from 'styled-components';

export const AppointmentCard = styled.div`
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`;

export const AppointmentInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateAndTime = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 8px;
`;

export const DoctorInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const DoctorImage = styled.img`
  border-radius: 50%;
  margin-right: 12px;
  width: 60px; // Adjust based on your design
  height: 60px; // Adjust based on your design
`;

export const DoctorDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DoctorName = styled.h3`
  font-size: 1.1rem;
  color: #333;
  margin: 0;
`;

export const Speciality = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  // Replace with actual icons
  margin-right: 5px;
`;

export const SpecialityText = styled.span`
  font-size: 0.9rem;
  color: #555;
`;

export const ArrowIcon = styled.span`
  // Placeholder, replace with an actual arrow icon
  font-size: 2rem;
  color: #333;
`;
