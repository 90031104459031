import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { Container as Input } from '../../components/Input/styles';

const background = '/images/icon/mailing.svg';

export const Title = styled.h1`
  ${({ theme }) => css`
    color: #9eb9c3;
    text-align: center;
    font-size: 3rem;
    font-family: ${theme.font.black};
    line-height: 2.3rem;
    letter-spacing: -0.08rem;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 2rem 5rem;
    background: white linear-gradient(180deg, #f2f8fc 0%, #ffffff 100%) 0% 0%
      no-repeat padding-box;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    background-image: url(${background});
    background-position: right bottom;
    height: 20rem;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    position: relative;
  `}
`;

export const HeaderIcon = styled.div`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
`;

export const Right = styled.div`
  ${({ theme }) => css``}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  margin: 2.4rem auto;

  button {
    height: 60px;
    font-size: 21px;
  }
`;

export const Group = styled.div`
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;

  ${Input} {
    width: 70%;
  }
`;

export const TitleGroup = styled.div`
  font-size: 2rem;
  color: #9eb9c3;
  text-align: center;
  width: 100%;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Resend = styled.div`
  cursor: pointer;
  color: #88a2af;
  background-color: ${rgba('#88A2AF', 0.1)};
  text-decoration: underline;
  font-size: 1.3rem;
  border-radius: 33px;

  padding: 2.5rem 1rem;
  text-align: center;
  width: calc(100% - 75%);
`;
