import React, { createContext, useState } from "react";
import { useContext } from "react";

export const UserContext = createContext();

export const useUser = () => {
  const context = useContext(UserContext);
  return context;
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    jwt: localStorage.getItem('@bluemed:jwt'),
    user: JSON.parse(localStorage.getItem('userInfo')),
    sharedToken: localStorage.getItem('sharedToken')
  });
  const [loading, setLoading] = useState(false);

  return (
    <UserContext.Provider
      value={{
        userInfo: user,
        setUser,
        loading,
        setLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
