import { addMinutes, setMinutes, setSeconds, setMilliseconds } from 'date-fns';
import { configSystem } from './CONFIG';

export const START_DATE_SCHEDULE = (() => {
  const now = configSystem.systemDateStartSchedule;
  const minutes = now.getMinutes();
  const roundedDate = setMinutes(now, minutes <= 30 ? 30 : 0);
  const adjustedDate = minutes > 30 ? addMinutes(roundedDate, 60) : roundedDate;
  return setMilliseconds(setSeconds(adjustedDate, 0), 0);
})();

export const QUEUE = "QUEUE";

export const APPOINTMENT = "APPOINTMENT";

export const whatsNumber = 11999999999;
export const whatsLink = `https://wa.me/${whatsNumber}`;

export const TOLERANCE_SCHEDULE = 10;