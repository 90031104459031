import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import * as S from './styles';

const PixQRCode = ({pixCode}) => {
  return (
    <S.ContainerQrCode>
      <QRCodeCanvas value={pixCode} size={256} />
    </S.ContainerQrCode>
  );
};

export default PixQRCode;
