import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback, useEffect, useState } from 'react';
import { LuCopy } from 'react-icons/lu';

import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { httpService } from '../../services/axios';
import Feedbacks from '../Feedbacks';
import * as S from './styles';
import PixQRCode from '../QrCode';
import { isNecessarySignatureAndPayment } from '../../rules';

let interval = null;
const CardOrPix = ({ action }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const navigate = useNavigate();
  const [pixInfo, setPixInfo] = useState(null);

  const hangleGetPixInfo = async () => {
    try {
      const response = await httpService.get(`/medical-appointment/generate-pix/sqala`);
      setPixInfo(response.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleCheckPaymentPix = useCallback(async () => {
    if (pixInfo?.id) {
      try {
        const { data } = await httpService.post(`/payment/check`, {
          id: pixInfo.id,
        });

        if (data.paid || isNecessarySignatureAndPayment) {
          await Swal.fire(
            'Sucesso!',
            'Pagamento realizado com sucesso.',
            'success',
          );
          localStorage.removeItem('@bluemed:medical-appointment-id')
          navigate(
            `/payment/success/pix?redirect_status=succeeded&payment_intent=${data.paymentId}&payment_intent_client_secret=${data.customerId}`,
          );
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  }, [navigate, pixInfo]);

  useEffect(() => {
    hangleGetPixInfo();
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (pixInfo) {
      interval = setInterval(() => {
        handleCheckPaymentPix();
      }, 3000); // 2,5 segundo
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [pixInfo, handleCheckPaymentPix]);

  const [method, setMethod] = useState('pix'); // Alterado de 'card' para 'pix'

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment/success/card`,
      },
    });
    if (error) {
      setPaymentError(!!error);
      setTimeout(() => {
        setPaymentError(false);
      }, 3000);
    }
    setIsLoading(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    Swal.fire({
      title: 'Copiado',
      text: 'Código copiado com sucesso',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

  };

  return (
    <>
      <Feedbacks
        show={paymentError}
        type='secondary'
        icon='bad-answer'
        title='Pagamento Recusado'
        description={() => (
          <>
            Lamentamos, mas o <br /> pagamento não foi processado, <br />{' '}
            confira todos os dados e <br /> tente novamente
          </>
        )}
      />

      <S.Container>

        {/* ADICIONADO ESTILIZAÇÃO PARA CENTRALIZAR O BOTÃO */}
        <S.Choose style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          {/* BOTÃO PARA METODOD DE CREDITO */}
          {/*  <S.ChooseItem
            active={method === 'card'}
            onClick={() => handleMethod('card')}
          >
            <S.ChooseBg>
              <S.ChooseIcon
                icon={`card${method === 'pix' ? '-gray' : ''}`}
                width='3.4rem'
                height='2.7rem'
              />
            </S.ChooseBg>
            <S.ChooseTitle>Cartão de crédito</S.ChooseTitle>
          </S.ChooseItem> */}

          <S.Grid>
            <S.PixGrid>
              <S.ChooseIcon
                icon="pix-blue"
                width="7.1rem"
                height="7.1rem"
              />
            </S.PixGrid>
            <S.PixTitle>PIX</S.PixTitle>
          </S.Grid>

          {/* <S.ChooseItem
            active={method === 'pix'}
            onClick={() => handleMethod('pix')}
          >
            <S.ChooseBg>
              <S.ChooseIcon
                icon={`pix${method === 'card' ? '-gray' : ''}`}
                width='4.1rem'
                height='4.1rem'
              />
            </S.ChooseBg>
            <S.ChooseTitle>PIX</S.ChooseTitle>
          </S.ChooseItem> */}
        </S.Choose>

        <S.Form onSubmit={handleSubmit}>
          {/* CAMPOS DO METODOD DE CREDITO */}
          {/*  {method === 'card' && <PaymentElement />} */}

          {(method === 'pix' && pixInfo !== null) && (
            <>
              <PixQRCode pixCode={pixInfo?.payload} />

              <S.PixCode onClick={() => copyToClipboard(pixInfo?.payload)}>
                {pixInfo?.payload}

                <S.ClickToCopy onClick={() => copyToClipboard(pixInfo?.payload)}>
                  <LuCopy />
                  Clique para copiar
                </S.ClickToCopy>
              </S.PixCode>
            </>
          )}
          {pixInfo == null && (
            <S.divText>
              <p>Gerando QrCode...</p>
            </S.divText>
          )}
          {/* <S.Disclaimer>
            Ao confirmar sua subscrição, <br /> você permite que <b>Bluemed®</b>{' '}
            cobre seu cartão <br /> por esse pagamento e pagamentos futuros{' '}
            <br /> de acordo com as condições.
          </S.Disclaimer> */}
          {method === 'card' && (
            <>
              {action && action(isLoading || !stripe || !elements, isLoading)}
            </>
          )}
        </S.Form>
      </S.Container>
    </>
  );
};

export default CardOrPix;
