import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiMail } from 'react-icons/fi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { GoShieldCheck } from 'react-icons/go';
import Button from '../../components/Button';
import Input from '../../components/Input';
import * as S from './styles';

import Header from '../../components/Header';
import { API_URL_MIDDLEWARE, USER_INFO_KEY } from '../../env';
import { httpService } from '../../services/axios';
import { schema } from './schema';

const ConfirmAccount = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const methods = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [loading, setLoading] = useState(false);

  const handleResendCode = async () => {
    try {
      setLoading(true);

      await httpService.post(`${API_URL_MIDDLEWARE}/users/resend-code`, {
        email: atob(params.get('hash')),
      });

      Swal.fire({
        title: `Código reenviado com sucesso.`,
        icon: 'success',
      });
    } catch (error) {
      Swal.fire({
        title: `Erro ao reenviar, tente novamente.`,
        icon: 'warning',
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const { data: response } = await httpService.put(
        `${API_URL_MIDDLEWARE}/users/confirm`,
        {
          ...data,
          email: atob(params.get('hash')),
        },
      );

      Swal.fire({
        title: 'Conta confirmada com sucesso!',
        icon: 'success',
      }).then(() => {
        localStorage.setItem(USER_INFO_KEY, JSON.stringify(response));
        navigate(`/face-detection`);
      });

      // /face-detection
    } catch (error) {
      Swal.fire({
        title: `Erro ao realizar a confirmação, tente novamente.`,
        icon: 'warning',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <S.Container>
        <S.Header>
          <S.HeaderIcon></S.HeaderIcon>
          <S.Title>
            Insira o código <br /> de confirmação
          </S.Title>
        </S.Header>

        <S.Right>
          <FormProvider {...methods}>
            <S.Form onSubmit={handleSubmit(onSubmit)}>
              <S.Group>
                <S.TitleGroup>
                  Enviamos um código de confirmação para o email{' '}
                  <b>{atob(params.get('hash'))}</b>
                </S.TitleGroup>

                <Input
                  styling='secondary'
                  label='Código de confirmação'
                  placeholder='Código'
                  {...register('code')}
                  name='code'
                  error={errors.code?.message}
                  icon={<FiMail size={20} />}
                />

                <S.Resend onClick={handleResendCode}>Reenviar</S.Resend>
              </S.Group>

              <Button
                variant='blue'
                size='small'
                disabled={loading}
                type='submit'
                iconColor='white'
              >
                Concluir Cadastro
                <GoShieldCheck />
              </Button>
            </S.Form>
          </FormProvider>
        </S.Right>
      </S.Container>
    </>
  );
};

export default ConfirmAccount;
