import { object, ref, string } from "yup";
import * as yup from "yup";

export const Step2Schema = object({
  rg: yup
    .string()
    .required("Oops, parece que você não adicionou um número para seu rg..."),
  cpf: yup
    .string()
    .required("Oops, parece que você não adicionou um número para seu cpf..."),
  dateOfBirth: yup
    .string()
    .required("Oops, parece que você não adicionou sua data de nascimento..."),
  gender: yup
    .string()
    .required("Oops, parece que você não selecionou um sexo..."),
});
