import Button from '../Button';
import * as S from './styles';

const CallToAction = ({
  children,
  disabled = false,
  size,
  isLoading,
  isLoadingMessage,
  type = 'button',
  onClick,
}) => {
  return (
    <S.Container disabled={disabled}>
      <S.Icon />
      <Button
        onClick={onClick}
        size={size}
        icon='right'
        disabled={disabled}
        type={type}
        isLoading={isLoading}
        isLoadingMessage={isLoadingMessage}
      >
        {children}
      </Button>
    </S.Container>
  );
};

export default CallToAction;
