import useAuthentication from '../../hooks/useAuthentication';
import * as S from './styles'

const REDIRECT = true
function DarkTemplate({ children, isPublic }) {
  useAuthentication(REDIRECT, isPublic);

  return <S.Container>{children}</S.Container>;
}

export default DarkTemplate;
