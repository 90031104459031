import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 7rem 3.5rem 11rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  position: relative;
`;

export const Logo = styled.img.attrs({
  src: "/images/bluemed/logo-inline.svg",
})`
  margin-bottom: 3rem;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 16px;
    font-style: normal;
    margin-top: 1em;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    color: ${theme.colors.gray};
    font-family: ${theme.font.regular};
  `}
`;

export const Subtitle = styled.h2`
  color: #d6d6d6;
  text-align: center;
  font-weight: normal;
  font-size: 1.2rem;
  margin-bottom: 4.3rem;
`;
