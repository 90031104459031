import styled, { css } from 'styled-components';

export const Container = styled.img.attrs(({ type }) => ({
  src: `/images/logo-${type}.svg`,
  alt: 'Logo',
}))`
  ${({ height, width }) => css`
    width: ${width};
    height: ${height};
  `}
`;
