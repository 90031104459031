// PrescriptioMacroView.js or PrescriptioMacroView.jsx
import React from 'react';
import * as S from './styles';

const PrescriptioMacroView = ({ title, onDownload }) => {
  return (
    <S.PrescriptionCard>
      <S.PrescriptionHeader>
        <S.Title>{title}</S.Title>
        <S.DownloadButton onClick={onDownload}>Baixar</S.DownloadButton>
      </S.PrescriptionHeader>
      <S.PrescriptionBody>
        <S.PrescriptionContent>
          {/* Content goes here, for example a blurred image or actual text */}
          <img src='path_to_blurred_prescription_image' alt='Prescription' />
        </S.PrescriptionContent>
      </S.PrescriptionBody>
    </S.PrescriptionCard>
  );
};

export default PrescriptioMacroView;
