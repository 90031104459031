import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import CallToAction from "../../components/CallToAction";
import Login from "../../components/Login";
import Logo from "../../components/Logo";
import { PiUserDuotone } from "react-icons/pi";

import * as S from "./styles";
import { TOKEN_NAME } from "../../env";
import { configSystem } from "../../CONFIG";

function Home() {
  const [showLogin, setShowLogin] = useState(false);

  const handleShowLogin = () => {
    document.querySelector("body").classList.toggle("-no-overflow");
    setShowLogin((prev) => !prev);
  };
  const navigate = useNavigate();

  const handleNavigate = () => {
    localStorage.removeItem(TOKEN_NAME);
    navigate("/contra-indications");
  }

  useEffect(() => {
    localStorage.removeItem('@bluemed:medical-appointment-id')
  }, [])

  if(configSystem.systemInMaintenance){
    return (
      //  // TELA DE MANUTENÇÃO
      <>
        <S.Container $hide={showLogin}>
          <S.Top>
            <S.Header>
              <Logo />
            </S.Header>
            <S.Presentation />
            <S.Group>
              <S.Title>
                Sistema em <br></br><br></br><br></br> manutenção
              </S.Title>

              <S.Description>
                Estamos atualizando nossa plataforma, por favor, volte mais tarde.
              </S.Description>

              <S.Description>
                contato: atendimento@bluepharm.cc
              </S.Description>
            </S.Group>
          </S.Top>
          <S.Bottom>
            <Button
              variant='outline'
              size='large'
              iconColor='blue'
              onClick={handleShowLogin}
            >
              Acessar minha conta <PiUserDuotone />

            </Button>
          </S.Bottom>
        </S.Container>
        <Login
          show={showLogin}
          toggleLogin={handleShowLogin}
          loginRedirect="/profile"
        />
      </>
    )
  }

  return (
    <>
      <S.Container $hide={showLogin}>
        <S.Top>
          <S.Header>
            <Logo />
          </S.Header>
          <S.Presentation />
          <S.Group>
            <S.Title>
              Agora você pode, <br></br>é seu <S.Icon /> direito.
            </S.Title>

            <S.Description>
              Agende suas consultas on-line, a onde você estiver!
            </S.Description>
          </S.Group>

          <CallToAction onClick={handleNavigate}>
            Fale com o Médico
          </CallToAction>
        </S.Top>

        <S.Bottom>
          <Button
            variant='outline'
            size='large'
            iconColor='blue'
            onClick={handleShowLogin}
          >
            Acessar minha conta <PiUserDuotone />

          </Button>
        </S.Bottom>
      </S.Container>
      <Login
        show={showLogin}
        toggleLogin={handleShowLogin}
        loginRedirect="/profile"
      />
    </>
   
  
  );
}

export default Home;
