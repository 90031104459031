import { useEffect, useState } from "react";
import * as S from "./styles";
import { START_DATE_SCHEDULE } from "./../../../../CONSTANTS";
import { useCallback } from "react";
import Swal from "sweetalert2";
import { promiseAwait } from "../../../../utils/promiseAwait";
import { useNavigate } from "react-router-dom";
import useMedicalAppointment from "../../hooks/useMedicalAppointment.js";
import { httpService } from "../../../../services/axios";
import ReactDatePicker from "react-datepicker";
import Button from "../../../../components/Button";
import Feedbacks from '../../../../components/Feedbacks';

export function Schedule({ appointment }) {
  const navigate = useNavigate();

  const [selectedDoctor, setSelectedDoctor] = useState({ id: "" });
  const [scheduled, setScheduled] = useState(false);
  
  const [startDate, setStartDate] = useState();

  const { doctors, isLoading, checkDate, checkTime } = useMedicalAppointment(
    selectedDoctor?.id
  );

  const isSelectableTime = (date) => {
    return checkTime(date)
  };

  const isSelectableDate = (date) => {
    return checkDate(date)
  };

  const onSchedule = useCallback(async () => {
    try {
      Swal.fire({
        title: "Aguarde",
        html: "Estamos agendando sua consulta...",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      await updateSchedule(startDate, "scheduled", appointment);

      Swal.close();
      setScheduled(true);

      await promiseAwait();

      navigate(`/profile`);
      Swal.close();
    } catch (error) {
      Swal.close();
      console.log(error?.response?.data?.message);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "Algo deu errado, tente novamente mais tarde!",
      });
    }
  }, [selectedDoctor, startDate]);

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const updateSchedule = useCallback(
    async (scheduleDate, status, appointment) => {
      await httpService.put(`/medical-appointment/${appointment}`, {
        ...(scheduleDate && { scheduleDate }),
        anamnesis: localStorage.getItem("anamnesesId"),
        status,
        doctor: selectedDoctor.id,
        timeZone: timeZone,
      });
    },
    [selectedDoctor.id, timeZone]
  );

  if (scheduled) {
    return (
      <Feedbacks
        show={scheduled}
        title={() => (
          <span>
            Agendamento feito <br /> com sucesso
          </span>
        )}
        description={() =>
          `Você tem uma consulta marcada para o dia ${startDate.toLocaleDateString(
            "pt-BR"
          )} às ${startDate.toLocaleTimeString("pt-BR")}`
        }
        loading
      />
    );
  }

  return (
    <S.Bottom>
      <S.Disclaimer>
        <b> Agende sua consulta agora mesmo!</b>
      </S.Disclaimer>

      <S.WrapSelect>
        <S.Select
          onChange={(e) => {
            setSelectedDoctor(
              doctors.find((doctor) => doctor.id.toString() === e.target.value)
            );
          }}
          value={selectedDoctor?.id}
        >
          <option value="" disabled>
            {isLoading ? "Carregando..." : "Selecione um médico"}
          </option>
          {doctors.map((doctor) => (
            <option
              key={doctor.id}
              value={doctor.id}
            >
              {doctor.name}
            </option>
          ))}
        </S.Select>
      </S.WrapSelect>

      {selectedDoctor?.id ? (
        <ReactDatePicker
          selected={startDate}
          locale="pt"
          onChange={(date) => {
            setStartDate(date);
          }}
          placeholderText="Selecione um período..."
          showTimeSelect
          minDate={new Date()}
          filterDate={isSelectableDate}
          filterTime={isSelectableTime}
          dateFormat="d MMMM yyyy - H:mm"
          disabled={isLoading}
          timeIntervals={30}
          utcOffset={0}
        />
      ) : null}

      <S.YSpace />
      <S.Actions>
        <Button
          size="small"
          onClick={() => setStartDate('')}
          disabled={isLoading}
        >
          Limpar
        </Button>

        <Button
          size="small"
          onClick={() => onSchedule(startDate)}
          disabled={isLoading}
        >
          Marcar
        </Button>
      </S.Actions>
    </S.Bottom>
  );
}
