import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiUser } from 'react-icons/fi';
import { TbMailBolt } from 'react-icons/tb';
import Swal from 'sweetalert2';
import { ObjectSchema, string } from 'yup';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Input from '../../components/Input';
import { httpService } from '../../services/axios';
import * as S from './styles';

function ForgotPassword() {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const Schema = new ObjectSchema({
    email: string().email('Insira um email válido').required('Insira o email'),
  });

  const methods = useForm({
    resolver: yupResolver(Schema),
  });
  const errors = methods.formState.errors;

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      const response = await httpService.post(`/auth/password/reset`, value);
      Swal.fire({
        title: 'Email enviado',
        text: 'Cheque seu email',
        icon: 'success',
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        Swal.fire({
          title: 'Erro',
          text: 'Esse email não existe',
          icon: 'error',
        });
      } else {
        Swal.fire({
          title: 'Erro',
          text: 'Ocorreu um erro ao tentar recuperar a senha',
          icon: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (loading) {
  //     Swal.fire({
  //       title: 'Carregando',
  //       text: 'Enviando a recuperação no email',
  //     });
  //   }
  // }, [loading]);

  return (
    <>
      <Header />
      <S.Container>
        <S.Top>
          <TbMailBolt />

          <S.Title>
            Para recuperar a senha.<br></br>
            Insira seu email abaixo
          </S.Title>
        </S.Top>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <S.Wrapper>
              <Input
                styling='secondary'
                label='Email'
                placeholder='Email'
                name='email'
                icon={<FiUser size={20} />}
                onChange={(e) => setValue(e.target.value)}
                onInput={(e) => e.target.value = e.target.value.toLowerCase()}
                autoCapitalize="none"
                error={errors.email?.message}
              />
              <S.VSpace $space={10} />
              <Button
                variant='outline'
                icon='right'
                iconColor='blue'
                size='large'
                disabled={loading}
              >
                Recuperar a senha
              </Button>
            </S.Wrapper>
          </form>
        </FormProvider>
      </S.Container>
    </>
  );
}

export default ForgotPassword;
