import BlankTemplate from "../templates/BlankTemplate";
import DarkTemplate from "../templates/DarkTemplate";
import MainTemplate from "../templates/MainTemplate";

import ContraIndications from "../pages/Flow/ContraIndications";

import AuthGoogle from "../pages/AuthGoogle";
import ConfirmAccount from "../pages/ConfirmAccount";
import FaceDetection from "../pages/FaceDetection";
import Screening from "../pages/Flow/Screening";
import ForgotPassword from "../pages/ForgotPassword";
import Home from "../pages/Home";
import MedicalAppointment from "../pages/MedicalAppointment";
import MedicalAppointmentWithRoom from "../pages/MedicalAppointmentWithRoom";
import MedicalVision from "../pages/MedicalVision";
import NotFound from "../pages/NotFound";
import PaymentSuccess from "../pages/PaymentSuccess";
import Profile from "../pages/Profile";
import AccountSettings from "../pages/Profile/AccountSettings";
import Documents from "../pages/Profile/Documents";
import FAQ from "../pages/Profile/FAQ";
import Orders from "../pages/Profile/Orders";
import Appointment from "../pages/Profile/Orders/Order";
import RecoverPassword from "../pages/RecoverPassword";
import SelectPlan from "../pages/SelectPlan";
import Signup from "../pages/Signup";
import Terms from "../pages/Terms";
import TermsAndAccept from "../pages/TermsAndAccept";
import VideoTest from "../pages/VideoTest";
import { configSystem } from "../CONFIG";

let mainRoutes = [
  {
    path: "/video-test/:name",
    isPublic: true,
    template: BlankTemplate,
    page: VideoTest,
    title: "VideoTest",
  },
  {
    path: "/",
    isPublic: true,
    template: BlankTemplate,
    page: Home,
    title: "Home",
  },
  {
    path: "/contra-indications",
    isPublic: true,
    template: BlankTemplate,
    page: ContraIndications,
    title: "Contra indicações",
  },
  {
    path: "/screening",
    isPublic: true,
    template: BlankTemplate,
    page: Screening,
    title: "Triagem",
  },
  {
    path: "/terms-and-accept",
    isPublic: true,
    template: BlankTemplate,
    page: TermsAndAccept,
    title: "Termos e aceite",
  },
  {
    path: "/payment/success/:method",
    isPublic: true,
    template: BlankTemplate,
    page: PaymentSuccess,
    title: "Pagamento realizado com sucesso",
  },
  {
    path: "/medical-appointment/:room",
    isPublic: true,
    template: BlankTemplate,
    page: MedicalAppointment,
    title: "Agendamento Médico",
  },
  {
    path: "/medical-appointment/:room/call",
    isPublic: true,
    template: BlankTemplate,
    page: MedicalAppointmentWithRoom,
    title: "Agendamento Médico",
  },
  {
    path: "/medical-vision/:room/appointment/:appointment/doctor/:doctor",
    isPublic: true,
    template: BlankTemplate,
    page: MedicalVision,
    title: "Agendamento Médico",
  },
  {
    path: "/confirm-account",
    isPublic: true,
    template: BlankTemplate,
    page: ConfirmAccount,
    title: "Confirme sua conta",
  },
  {
    path: "/terms/:term",
    isPublic: true,
    template: BlankTemplate,
    page: Terms,
    title: "Terms",
  },
  {
    path: "/create-account",
    isPublic: true,
    template: BlankTemplate,
    page: Signup,
    title: "Criar conta",
  },
  {
    path: "/select-plan",
    isPublic: true,
    template: BlankTemplate,
    page: SelectPlan,
    title: "Select Plan",
  },
  {
    path: "/face-detection",
    isPublic: true,
    template: DarkTemplate,
    page: FaceDetection,
    title: "FaceDetection",
  },
  {
    path: "/auth/google",
    isPublic: true,
    template: BlankTemplate,
    page: AuthGoogle,
    title: "Login com o google",
  },
  {
    path: "/forgot-password",
    isPublic: true,
    template: BlankTemplate,
    page: ForgotPassword,
    title: "Esqueceu a senha",
  },
  {
    path: "/recover-password",
    isPublic: true,
    template: BlankTemplate,
    page: RecoverPassword,
    title: "Esqueceu a senha",
  },
  {
    path: "/*",
    isPublic: true,
    template: MainTemplate,
    page: NotFound,
    title: "Not Found",
  },
];

const profileRoutes = [
  {
    path: "/profile",
    isPublic: true,
    template: BlankTemplate,
    page: Profile,
    title: "Profile",
  },
  {
    path: "/profile/settings",
    isPublic: true,
    template: BlankTemplate,
    page: AccountSettings,
    title: "AccountSettings",
  },

  {
    path: "/profile/documents",
    isPublic: true,
    template: BlankTemplate,
    page: Documents,
    title: "Seus documentos",
  },

  {
    path: "/profile/faq",
    isPublic: true,
    template: BlankTemplate,
    page: FAQ,
    title: "Dúvidas frequentes",
  },

  {
    path: "/profile/orders/appointment",
    isPublic: true,
    template: BlankTemplate,
    page: Appointment,
    title: "Seu agendamento",
  },

  {
    path: "/profile/orders",
    isPublic: true,
    template: BlankTemplate,
    page: Orders,
    title: "Seus pedidos",
  },
];

export default configSystem.systemInMaintenance
  ? [
      ...profileRoutes,
      {
        path: "/*",
        isPublic: true,
        template: BlankTemplate,
        page: Home,
        title: "Home",
      },
    ]
  : [...mainRoutes, ...profileRoutes];
