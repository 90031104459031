import styled from 'styled-components';

export const Container = styled.div`
  background: var(--WhiteBlue, linear-gradient(180deg, #f2f8fc 0%, #fff 100%));

  padding: 4em;
`;

export const DocumentTitle = styled.div`
  font-size: 1.7rem;
  font-weight: 600;
`

export const Title = styled.div`
  color: var(--Grey, #93aeba);
  margin-bottom: 1em;
  font-size: 23px;
  line-height: 15px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5em;
`;

export const Icon = styled.img`
  height: 22px;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
