import { useCallback, useEffect, useState } from 'react'
import { httpService } from '../../../services/axios'
import Template from '../Template'
import { getFromLocalStorage, saveToLocalStorage } from '../../../utils/localStorage'
const Screening = () => {
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  
  const handleGetListItems = useCallback(async () => {
    try {
      setIsLoading(true)
      const cacheData = getFromLocalStorage("@bluemed:screenings", 5)

      if(!cacheData){
        const { data: { data } } = await httpService.get(
          "/anamneses/flow/screenings"
        );
        saveToLocalStorage("@bluemed:screenings", data)
        setItems(data);
      } else {
        setItems(cacheData);
      }
    } catch (error) {
      console.log("error", error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    handleGetListItems()
  }, [handleGetListItems])

  const rightsQuestions = () => items?.map(item => item.id)

  return (
    <Template
      isLoading={isLoading}
      items={items}
      rightsQuestions={rightsQuestions()}
      presentation='screening'
      type='screening'
      title='Triagem'
      description='Você sente ou foi diagnosticado com algum dos sintomas abaixo?'
      nextPageTitle='Termos e Aceite'
      nextPageSize='medium'
      nextPage='/terms-and-accept'
    />
  )
}

export default Screening
