import { useEffect, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header/';
import Swal from 'sweetalert2';
import { httpService } from '../../services/axios';
import * as S from './styles';
import { Queue } from './components/Queue/index.js';
import { getFromLocalStorage, saveToLocalStorage } from '../../utils/localStorage.js';
import { Schedule } from './components/Schedule';
import { QUEUE, APPOINTMENT } from '../../CONSTANTS.js';
import { configSystem } from '../../CONFIG.js';
import { WhatsAppContact } from './components/WhatsAppContact/index.js';
import { useQueue } from '../../providers/QueueProvider/index.js';

const MedicalAppointment = () => {
  const navigate = useNavigate();
  const [appointment, setAppointment] = useState(getFromLocalStorage('@bluemed:medical-appointment-id'));
  const { hasDoctorsAvailable, canSchedule } = useQueue();
  const [type, setType] = useState((configSystem.systemQueueService === false || !hasDoctorsAvailable) ? APPOINTMENT : QUEUE);
  const params = useParams();

  useEffect(() => {
    if(!appointment){
      const controller = new AbortController();
      const signal = controller.signal;

      const handleCreateSchedule = async () => {
        try {
          Swal.fire({
            title: "Aguarde",
            html: "Estamos carregando...",
            didOpen: () => {
              Swal.showLoading();
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
          });
    
          const { data: response } = await httpService.post(
            `/medical-appointment`,
            {
              room: params.room,
              scheduleDate: new Date(),
              anamnesis: localStorage.getItem("anamnesesId"),
              status: "scheduled",
            },
            { signal }
          );
          console.log(response);
          saveToLocalStorage('@bluemed:medical-appointment-id', response?.id)
          setAppointment(response?.id);
        } catch (error) {
          console.log("error", error);
        } finally {
          Swal.close();
        }
      };

      handleCreateSchedule();
      return () => {
        controller.abort();
      };
    }
  }, [params.room, appointment, navigate]);

  const body = useMemo(() => {
    
    if(!canSchedule)
      return <WhatsAppContact appointment={appointment} setType={setType} />

    if(type === QUEUE && configSystem.systemQueueService){
      return <Queue appointment={appointment} setType={setType} />
    }

    if(type === APPOINTMENT){
      return <Schedule appointment={appointment} />
    }

    return <></>

  }, [appointment, setType, type, hasDoctorsAvailable]);
  return (
    <S.Container>
      <Header noBackground backAction={type === QUEUE || !hasDoctorsAvailable || configSystem.systemQueueService === false ? null : () => setType(QUEUE)} />
      {body}
    </S.Container >
  );
};

export default MedicalAppointment;
