import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ThemeProvider from './providers/ThemeProviders';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { QueueProvider } from './providers/QueueProvider';
import { UserProvider } from './providers/UserProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ThemeProvider>
      <UserProvider>
        <QueueProvider>
          <App />
        </QueueProvider>
      </UserProvider>
    </ThemeProvider>
  // </React.StrictMode>
);

serviceWorkerRegistration.unregister();
